import { PwaContext } from '@/app/context/PwaContext/PwaContext';
import { Close } from '@material-ui/icons';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

const ToolbarPwaAccouncement = () => {
	const { showPwaBanner, handlePwaPromptClick, handleClosePwaBanner } = useContext(PwaContext);
	const user = useSelector(({ auth }) => auth.user);

	const isLoggedIn = user.role.length;
	// const show = true;
	const show = showPwaBanner && isLoggedIn;

	// show nothing if already in the PWA
	if (window.matchMedia('(display-mode: standalone)').matches) return null;

	return (
		<div
			className={`bg-pd-blue flex items-center px-10 sm:px-32 gap-20 justify-between transition-all shadow-md  `}
			style={{
				height: '100%',
				maxHeight: show ? '60px' : '0px',
				opacity: show ? '1' : '0',
				transition: 'all 0.3s ease-in-out',
				backgroundColor: '#CCE0FF',
			}}
		>
			<div className="flex items-center gap-4 xs:gap-16">
				<button className="mr:0 sm:mr-16" style={{ color: '#02489B' }} onClick={handleClosePwaBanner}>
					<Close />
				</button>
				<img src="/assets/images/pd-admin-icon.png" className="max-w-48" />
				<p className="font-bold text-pd-xs md:text-pd-sm leading-tight" style={{ color: '#02489B' }}>
					Get quick access to Perfect Day Admin: Add it to your home screen.
				</p>
			</div>
			<div className="flex justify-center flex-col">
				<div className=" border-2 border-white  rounded-full">
					<button
						className="rounded-full text-white px-10 sm:px-32  py-4 sm:py-6 text-pd-base  transition-all"
						onClick={handlePwaPromptClick}
						style={{
							background: 'linear-gradient(to bottom, #CCE0FF, #0D57C6)',
							transition: 'box-shadow 0.225s ease-in-out',
							border: 'none',
						}}
					>
						Install
					</button>
				</div>
			</div>
		</div>
	);
};

export default ToolbarPwaAccouncement;
