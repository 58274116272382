import { PwaContext } from '@/app/context/PwaContext/PwaContext';
import { useContext } from 'react';

const NavPwaBanner = () => {
	const { handlePwaPromptClick, deferredPrompt, handleShowPwaInstructions } = useContext(PwaContext);
	//  check if this is running in standalone mode. That means we're in the PWA already.
	if (window.matchMedia('(display-mode: standalone)').matches) return null;
	return (
		<div
			className="w-full text-white  mb-10 gap-20 flex flex-col p-16 items-center"
			style={{ boxShadow: '5px 0px 15px 3px rgba(0,0,0,0.2)', backgroundColor: '#CCE0FF' }}
		>
			<div className="flex flex-col gap-10">
				<div className="flex  gap-10 items-start">
					<img src="/assets/images/pd-admin-icon.png" className="max-w-52" />
					<p className=" font-bold" style={{ color: '#02489B', fontSize: '12px' }}>
						Get quick access to Perfect Day Admin: Add it to your home screen.
					</p>
				</div>
				<div className="flex justify-center">
					<div className="border-2 border-white rounded-full">
						<button
							className="rounded-full   text-white px-32 py-6 text-pd-base shadow-blue-sm hover:shadow-blue-md transition-all"
							onClick={() => (deferredPrompt ? handlePwaPromptClick() : handleShowPwaInstructions())}
							style={{
								background: 'linear-gradient(to bottom, #CCE0FF, #0D57C6)',
								transition: 'box-shadow 0.225s ease-in-out',
								border: 'none',
							}}
						>
							Install
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NavPwaBanner;
