import React, { useEffect, useState, useRef } from 'react';
import {
	TextField,
	InputLabel,
	MenuItem,
	FormControl,
	Select,
	FormHelperText,
	CircularProgress,
	Avatar,
	IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import { getRoomsEnrollStd } from '@/app/services/rooms/rooms';
import { enrollStudent, getExistingParents, preFetchData } from '@/app/services/students/students';
import { uploadFile } from '@/app/services/imageUpload/imageUpload';
import './enrollStudent.css';
import { useDispatch } from 'react-redux';
import * as Actions from '@/app/store/actions';
import dayjs from 'dayjs';
import history from '@/@history';
import CustomDatePicker from '@/app/customComponents/CustomDatePicker/CustomDatePicker';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { scrollIntoView, getImageUrl, isImageFile, isInValidName } from '@/utils/utils';
import { getSearchableCityList, getSearchableStateList } from '@/app/services/schools/schools';
import { getCountryList } from '@/app/services/settings/settings';
import { debounce } from 'lodash';
import { isPossiblePhoneNumber } from 'libphonenumber-js/min';
import CustomPhoneInput from '@/app/customComponents/CustomPhoneInput/CustomPhoneInput';
import PageHeader from '@/app/customComponents/PageHeader';

const useStyles = makeStyles({
	select: {
		'&:before': {
			borderBottom: 'none',
		},
		'&:after': {
			borderBottom: 'none',
		},
		'&:not(.Mui-disabled):hover::before': {
			borderBottom: 'none',
		},
		'& .MuiSelect-select:focus': {
			backgroundColor: 'inherit',
		},
		'& .MuiSvgIcon-root': {
			color: 'inherit',
		},
		color: 'inherit',
		'&:hover': {
			color: 'inherit',
		},
	},
	content: {
		position: 'relative',
		display: 'flex',
		overflow: 'auto',
		flex: '1 1 auto',
		flexDirection: 'column',
		width: '100%',
		'-webkit-overflow-scrolling': 'touch',
		zIndex: 2,
	},
});
function EnrollStudents() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [form, setForm] = useState({
		city: '',
		zip_code: '',
		state_id: '',
		parent_first_name: '',
		parent_last_name: '',
		parent_phone: '',
		gender: '',
		room_id: '',
		country_id: '',
		isNew: 0,
		is_primary_account: 1,
	});
	const [rooms, setRooms] = useState([]);
	const [page, setPage] = useState(1);
	const [errTxts, setErrTxts] = useState({
		city: '',
		zipcode: '',
		state_id: '',
	});
	const [isLoading, setIsLoading] = useState(false);
	const [date, setDate] = useState(null);
	const [existingParents, setExistingParents] = useState([]);
	const [parentEmail, setParentEmail] = useState('');
	const [role, setRole] = useState('parent');
	const [loadingParents, setLoadingParents] = useState(false);
	const [preview, setPreview] = useState();
	const [selectedFile, setSelectedFile] = useState(null);
	const [searchStateQuery, setStateSearchQuery] = useState('');
	const [isCityLoading, setIsCityLoading] = useState(false);
	const [searchCityQuery, setSearchCityQuery] = useState('');
	const [defaultCity, setDefaultCity] = useState('');
	const [defaultState, setDefaultState] = useState('');
	const [countries, setCountries] = useState([]);
	const [city, setCity] = useState([]);
	const [states, setStates] = useState([]);
	const [isStateloading, setIsStateloading] = useState(false);

	const inputRef = useRef(null);

	useEffect(() => {
		let isMounted = true;
		getCountryList()
			.then((res) => {
				if (!isMounted) return;
				setCountries(res.data);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get countries.',
						autoHideDuration: 1500,
						variant: 'error',
					})
				);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		let isMounted = true;
		// if email doesnt exist or invalid email is entered, dont call api
		if (!form?.parent_email) return;
		if (!/^\S+@\S+\.\S+$/.test(form.parent_email)) return;

		const fetchData = async () => {
			// Make your API call here
			const res = await preFetchData(form?.parent_email?.trim());

			if (res?.data) {
				if (!isMounted) return;
				setForm((prevForm) => ({
					...prevForm,
					parent_first_name: res?.data?.first_name,
					parent_last_name: res?.data?.last_name,
					parent_phone: res?.data?.phone,
				}));
			}
		};

		// Create a debounced function with a delay of 1000ms
		const debouncedFetchData = debounce(fetchData, 1000);

		// Call the debounced function every time form.email changes
		if (form.isNew == 1) {
			debouncedFetchData();
		}

		// Cleanup function to cancel the debounced function
		return () => {
			isMounted = false;
			debouncedFetchData.cancel();
		};
	}, [form?.parent_email]);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(() => {
			if (!isMounted) return;
			setIsStateloading(true);

			getSearchableStateList(searchStateQuery, searchStateQuery ? undefined : 1)
				.then((res) => {
					if (!isMounted) return;
					setStates(res?.data?.data);
				})
				.catch((err) => {
					if (!isMounted) return;
					dispatch(
						Actions.showMessage({
							message: 'Failed to get states.',
							autoHideDuration: 1500,
							variant: 'error',
						})
					);
				})
				.finally(() => {
					if (!isMounted) return;
					setIsStateloading(false);
				});
		}, 1000);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [dispatch, searchStateQuery]);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(() => {
			if (!isMounted) return;
			setIsCityLoading(true);
			setForm({ ...form, city: '' });
			setCity([]);
			if (!searchCityQuery && !form.state_id) {
				setForm({ ...form, city: '' });
				setCity([]);
			} else {
				getSearchableCityList(form?.state_id, searchCityQuery, 1)
					.then((res) => {
						if (!isMounted) return;
						setForm({ ...form, city: '' });
						setCity(res?.data?.data);
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to get cities.',
								autoHideDuration: 1500,
								variant: 'error',
							})
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsCityLoading(false);
					});
			}
		}, 1000);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [searchCityQuery, defaultState]);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(() => {
			if (!isMounted) return;
			setLoadingParents(true);
			getExistingParents(parentEmail)
				.then((res) => {
					if (!isMounted) return;
					setExistingParents(res?.data?.data);
					setLoadingParents(false);
				})
				.catch((err) => {
					if (!isMounted) return;
					setLoadingParents(false);
				});
		}, 1000);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [parentEmail]);

	useEffect(() => {
		if (!selectedFile) {
			setPreview(undefined);
			return;
		}

		const objectUrl = URL.createObjectURL(selectedFile);
		setPreview(objectUrl);

		return () => URL.revokeObjectURL(objectUrl);
	}, [selectedFile]);

	const onSelectFile = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(undefined);
			return;
		}
		const file = e.target.files[0];
		if (!isImageFile(file)) {
			dispatch(Actions.showMessage({ message: 'Please select an image file', variant: 'error' }));
		} else {
			setSelectedFile(e.target.files[0]);
		}
	};

	const handleChange = (ev) => {
		const { name, value } = ev.target;
		if (name === 'state_id') {
			setErrTxts({ ...errTxts, state_id: '' });
			return;
		}
		if (name === 'city') {
			setErrTxts({ ...errTxts, city: '' });
		}
		if (name === 'zip_code') {
			// Only Number, Max 5, can be empty
			if (value && !/^[0-9]{0,5}$/.test(value)) {
				return;
			}
			setErrTxts({ ...errTxts, zipcode: '' });
		}
		if (value == 1) {
			setForm({
				...form,
				parent_first_name: '',
				parent_last_name: '',
				parent_phone: '',
				[name]: value,
			});
			setErrTxts({ ...errTxts, [name]: [] });
			return;
		}
		setErrTxts({ ...errTxts, [name]: [] });
		setForm({ ...form, [name]: value });
	};

	const handleSubmit = async (ev) => {
		ev.preventDefault();
		setErrTxts({});
		if (!form.first_name) {
			setErrTxts({ ...errTxts, first_name: 'This field is required' });
			scrollIntoView('fname-student');
			return;
		}
		if (form.first_name && isInValidName(form.first_name)) {
			setErrTxts({ ...errTxts, first_name: 'Please enter a valid name.' });
			scrollIntoView('fname-student');
			return;
		}
		form.first_name = form.first_name.trim();
		if (!form.last_name) {
			setErrTxts({ ...errTxts, last_name: 'This field is required' });
			scrollIntoView('lname-student');
			return;
		}
		if (form.last_name && isInValidName(form.last_name)) {
			setErrTxts({ ...errTxts, last_name: 'Please enter a valid name.' });
			scrollIntoView('lname-student');
			return;
		}
		form.last_name = form.last_name.trim();
		if (!form.dob) {
			setErrTxts({ ...errTxts, dob: 'This field is required' });
			scrollIntoView('date-of-birth');
			return;
		}
		if (!form.room_id) {
			setErrTxts({ ...errTxts, room_id: 'This field is required' });
			scrollIntoView('homeroom');
			return;
		}
		if (!form.gender) {
			setErrTxts({ ...errTxts, gender: 'This field is required' });
			scrollIntoView('gender');
			return;
		}

		if (!form.isNew) {
			setErrTxts({ ...errTxts, isNew: 'This field is required' });
			scrollIntoView('isNew');
			return;
		}
		if (form.isNew === 1) {
			if (!form.parent_first_name) {
				setErrTxts({ ...errTxts, parent_first_name: 'This field is required' });
				scrollIntoView('parent_first_name');
				return;
			}
			if (form.parent_first_name && isInValidName(form.parent_first_name)) {
				setErrTxts({ ...errTxts, parent_first_name: 'Please enter a valid name.' });
				scrollIntoView('parent_first_name');
				return;
			}
			form.parent_first_name = form.parent_first_name.trim();
			if (!form.parent_last_name) {
				setErrTxts({ ...errTxts, parent_last_name: 'This field is required' });
				scrollIntoView('parent_last_name');
				return;
			}
			if (form.parent_last_name && isInValidName(form.parent_last_name)) {
				setErrTxts({ ...errTxts, parent_last_name: 'Please enter a valid name.' });
				scrollIntoView('parent_last_name');
				return;
			}
			form.parent_last_name = form.parent_last_name.trim();
			if (!form.relation_with_child) {
				setErrTxts({ ...errTxts, relation_with_child: 'This field is required' });
				scrollIntoView('relation_with_child');
				return;
			}
			if (!form.parent_phone) {
				setErrTxts({ ...errTxts, parent_phone: 'This field is required' });
				scrollIntoView('parent_phone');
				return;
			}
			if (!form.parent_email) {
				setErrTxts({ ...errTxts, parent_email: 'This field is required' });
				scrollIntoView('parent_email');
				return;
			}
			if (!/^\S+@\S+\.\S+$/.test(form.parent_email)) {
				setErrTxts({ ...errTxts, parent_email: 'Please enter valid email' });
				scrollIntoView('parent_email');
				return;
			}
			if (form.parent_phone) {
				if (isPossiblePhoneNumber(form.parent_phone, 'US') === false) {
					setErrTxts({ ...errTxts, parent_phone: 'Please enter valid phone number' });
					scrollIntoView('parent_phone');
					return;
				}
			}
		}
		if (form.isNew === 2) {
			if (!form.parent_id) {
				setErrTxts({ ...errTxts, parent_id: 'This field is required' });
				scrollIntoView('parent_id');
				return;
			}
			delete form.parent_first_name;
			delete form.parent_last_name;
			delete form.parent_phone;
			delete form.parent_email;
			delete form.relation_with_child;
		}
		if (form.isNew === 1) {
			form.emergency_contact = true;
			delete form.parent_id;
		}
		if (form.isNew === 2) {
			form.emergency_contact = true;
		}
		if (!form.city_id) {
			delete form.city_id;
		}
		if (!form.state_id) {
			delete form.state_id;
		}
		if (!form.zip_code) {
			delete form.zip_code;
		}
		setIsLoading(true);
		if (selectedFile) {
			const filename = getImageUrl(selectedFile);
			try {
				const response = await uploadFile(selectedFile, filename);
				form.photo = `${import.meta.env.VITE_S3_BASE_URL}${response}`;
				form.thumb = `${import.meta.env.VITE_S3_BASE_URL}${response}`;
			} catch (err) {
				dispatch(
					Actions.showMessage({
						message: 'Failed to upload image.',
						autoHideDuration: 2000,
						variant: 'error',
					})
				);
				setIsLoading(false);
				return;
			}
		} else {
			form.photo = `${import.meta.env.VITE_S3_BASE_URL}${import.meta.env.VITE_PERSONAL_AVATAR_URL}`;
			form.thumb = `${import.meta.env.VITE_S3_BASE_URL}${import.meta.env.VITE_PERSONAL_AVATAR_URL}`;
		}
		enrollStudent(form)
			.then((res) => {
				dispatch(
					Actions.showMessage({
						message: 'Student Enrolled Successfully.',
						autoHideDuration: 1500,
						variant: 'success',
					})
				);
				history.push('/students');
			})
			.catch((err) => {
				dispatch(
					Actions.showMessage({
						message: err?.response?.data?.message || 'Failed to enroll student.',
						autoHideDuration: 2000,
						variant: 'error',
					})
				);
				if (err?.response?.data?.errors) {
					setErrTxts(err?.response?.data?.errors);
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		let isMounted = true;
		getRoomsEnrollStd('', page)
			.then((res) => {
				if (!isMounted) return;
				setRooms([...rooms, ...res.data.data]);
				if (res?.data?.current_page < res?.data?.last_page) {
					setPage(page + 1);
				}
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get rooms.',
						autoHideDuration: 1500,
						variant: 'error',
					})
				);
			});
		return () => {
			isMounted = false;
		};
	}, [page, dispatch]);

	useEffect(() => {
		if (date) {
			if (dayjs(date).format('YYYY-MM-DD') === form.dob) {
				return;
			}
			form.dob = dayjs(date).format('YYYY-MM-DD');
		} else {
			form.dob = '';
		}
	}, [date, form.dob]);

	const handleGoBack = () => {
		history.goBack();
	};

	return (
		<div className="px-64 py-60 mb-64">
			<PageHeader title="Enroll Student" onBack={handleGoBack} />
			<div className="bg-white p-60 mt-20 rounded-12 shadow-lg">
				<form onSubmit={handleSubmit}>
					<div className="gap-36 justify-center items-center flex flex-col md:flex-row md:items-start">
						<div
							className="relative pic-upload-overlay cursor-pointer flex-grow"
							onClick={() => inputRef.current.click()}
						>
							<Avatar style={{ height: '140px', width: '140px' }} src={preview} className="imageupload" />
							<div className="pp-input-overlay studentAdd">
								<i className="fa fa-2x fa-camera" />
							</div>
							<input
								onChange={onSelectFile}
								type="file"
								name="image"
								id="image"
								accept="image/*"
								className="hidden"
								ref={inputRef}
							/>
						</div>
						<fieldset style={{ width: '100%', marginLeft: '20px' }}>
							<div className=" md:mb-16">
								<h2 className="form-section-heading" style={{ fontSize: '18px' }}>
									Student Information
								</h2>
							</div>
							<div className="grid md:grid-cols-2 grid-cols-1 gap-x-40 gap-y-20">
								<TextField
									onChange={handleChange}
									name="first_name"
									label="First Name*"
									id="fname-student"
									fullWidth
									error={!!errTxts.first_name?.length}
									helperText={errTxts.first_name}
								/>
								<TextField
									onChange={handleChange}
									name="last_name"
									label="Last Name*"
									id="lname-student"
									fullWidth
									error={!!errTxts.last_name?.length}
									helperText={errTxts.last_name}
								/>

								<CustomDatePicker
									fullWidth
									errTxts={errTxts.dob}
									name="dob"
									value={date}
									setValue={(d) => {
										setDate(d);
										setErrTxts({ ...errTxts, dob: '' });
									}}
									label="Date of Birth*"
									className="text-"
									disableFuture
									id="date-of-birth"
								/>
								<FormControl error={!!errTxts.room_id?.length} variant="standard" className="">
									<InputLabel id="homeroomLabel">Select a Home Room*</InputLabel>
									<Select
										name="room_id"
										onChange={handleChange}
										value={form.room_id}
										labelId="homeroomLabel"
										id="homeroom"
										label="Select a Home Room*"
									>
										{rooms.length ? (
											rooms.map((room) => {
												return (
													<MenuItem key={room.id} value={room.id} id={room.name}>
														{room.name}
													</MenuItem>
												);
											})
										) : (
											<MenuItem disabled>Loading...</MenuItem>
										)}
									</Select>
									{errTxts.room_id && <FormHelperText>{errTxts.room_id}</FormHelperText>}
								</FormControl>

								<FormControl error={!!errTxts.gender?.length} variant="standard">
									<InputLabel id="genderLabel">Gender*</InputLabel>
									<Select
										name="gender"
										onChange={handleChange}
										labelId="genderLabel"
										id="gender"
										label="Select Gender*"
										value={form.gender}
									>
										<MenuItem value="male">
											<span id="male"> Male</span>
										</MenuItem>
										<MenuItem value="female">
											<span id="female"> Female</span>
										</MenuItem>
									</Select>
									{errTxts.gender && <FormHelperText>{errTxts.gender}</FormHelperText>}
								</FormControl>
								<TextField onChange={handleChange} name="address1" id="address1" label="Address" />

								<FormControl className="">
									<TextField
										name="country_id"
										select
										label="Country"
										value={form.country_id}
										onChange={handleChange}
										id="country_id"
										error={!!errTxts.country_id?.length}
										helperText={errTxts.country_id}
									>
										{countries?.length ? (
											countries.map((country) => {
												return (
													<MenuItem key={country.id} value={country.id}>
														{country.name}
													</MenuItem>
												);
											})
										) : (
											<MenuItem>Loading...</MenuItem>
										)}
									</TextField>
								</FormControl>

								<Autocomplete
									id="state-autocomplete"
									name="state_id"
									options={states}
									renderOption={(option) => (
										<>
											<div className="flex" style={{ gap: 10 }}>
												<div>{option.name}</div>
											</div>
										</>
									)}
									getOptionLabel={(option) => option.name}
									autoComplete={false}
									clearOnBlur={false}
									disableClearable
									loading={isStateloading}
									loadingText="...Loading"
									onChange={(_e, v) => {
										setDefaultState(v);
										setForm({ ...form, state_id: v?.id || '' });
										setErrTxts({ ...errTxts, state_id: '' });
									}}
									onInputChange={(e, value) => {
										setStateSearchQuery(value);
										if (value === '') {
											setForm({ ...form, state_id: '', city: '' });
										}
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="State"
											error={!!errTxts.state_id?.length}
											helperText={errTxts.state_id}
											autoComplete="off"
										/>
									)}
								/>

								<Autocomplete
									className=""
									id="city"
									name="city"
									options={city}
									renderOption={(option) => (
										<>
											<div className="flex" style={{ gap: 10 }}>
												<div>{option.name}</div>
											</div>
										</>
									)}
									getOptionLabel={(option) => option.name}
									autoComplete={false}
									clearOnBlur={false}
									disableClearable
									loading={isCityLoading}
									loadingText="...Loading"
									sx={{ width: '100%' }}
									onChange={(_e, v) => {
										setDefaultCity(v);
										setForm({ ...form, city_id: v?.id || '' });
										setErrTxts({ ...errTxts, city: '' });
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="City"
											onChange={(e) => {
												setSearchCityQuery(e.target.value);
												if (e.target.value === '') {
													setForm({ ...form, city_id: '' });
												}
											}}
											style={{ width: '100%' }}
											error={!!errTxts.city_id?.length}
											helperText={errTxts.city_id}
											autoComplete="off"
										/>
									)}
								/>
								<TextField
									name="zip_code"
									label="Zip Code"
									id="zip_code"
									onChange={handleChange}
									value={form.zip_code}
									error={!!errTxts.zipcode?.length}
									helperText={errTxts.zipcode}
								/>
							</div>
							<div className="mt-52 md:mb-16">
								<div className="flex justify-between">
									<h2 className="form-section-heading" style={{ fontSize: '18px' }}>
										Parent Information
									</h2>
									<FormControl error={!!errTxts.isNew?.length} variant="standard" className="">
										<div className="flex">
											<CustomButton variant="secondary" height="40" width="120px" fontSize="15px">
												<Select
													className={classes.select}
													name="isNew"
													defaultValue={0}
													onChange={handleChange}
													id="isNew"
													value={form.isNew}
												>
													<MenuItem className="hidden" value={0} disabled>
														Select
													</MenuItem>
													<MenuItem value={1}>
														<span id="new"> New </span>
													</MenuItem>
													<MenuItem value={2}>
														<span id="existing"> Existing </span>
													</MenuItem>
												</Select>
											</CustomButton>
										</div>
										{errTxts.isNew && <FormHelperText>{errTxts.isNew}</FormHelperText>}
									</FormControl>
								</div>
							</div>
							{form.isNew === 1 ? (
								<>
									<fieldset className="grid md:grid-cols-2 grid-cols-1 gap-x-40 gap-y-20">
										<TextField
											onChange={handleChange}
											name="parent_first_name"
											label="First Name*"
											id="fname-parent"
											value={form?.parent_first_name}
											error={!!errTxts.parent_first_name?.length}
											helperText={errTxts.parent_first_name}
										/>
										<TextField
											onChange={handleChange}
											name="parent_last_name"
											label="Last Name*"
											id="lname-parent"
											value={form?.parent_last_name}
											error={!!errTxts.parent_last_name?.length}
											helperText={errTxts.parent_last_name}
										/>

										<FormControl variant="standard">
											<InputLabel shrink id="selectLabel">
												Select*
											</InputLabel>
											<Select
												value={role}
												onChange={(e) => setRole(e.target.value)}
												labelId="selectLabel"
												id="Select"
												label="Select*"
											>
												<MenuItem value="parent">
													<span id="parent">Parent</span>
												</MenuItem>
												<MenuItem value="legal-guardian">
													<span id="guardian">Legal Guardian</span>
												</MenuItem>
											</Select>
										</FormControl>
										<FormControl error={errTxts.relation_with_child?.length} variant="standard">
											<InputLabel id="relationLabel">Relation with Child*</InputLabel>
											<Select
												onChange={handleChange}
												labelId="relationLabel"
												id="childRelation"
												label="Relation with Child*"
												name="relation_with_child"
											>
												{role === 'parent' ? (
													// Rendered as an array because select doesn't accept a fragment
													[
														<MenuItem key="father" value="father">
															<span id="father">Father</span>
														</MenuItem>,
														<MenuItem key="mother" value="mother">
															<span id="mother">Mother</span>
														</MenuItem>,
													]
												) : (
													<MenuItem value="Legal Guardian">
														<span id="legal-guardian">Legal Guardian</span>
													</MenuItem>
												)}
											</Select>
											{errTxts.relation_with_child && (
												<FormHelperText>{errTxts.relation_with_child}</FormHelperText>
											)}
										</FormControl>

										<CustomPhoneInput
											value={form.parent_phone}
											onChange={handleChange}
											label={'Contact Number'}
											name="parent_phone"
											id="parent-phone"
											required
											error={!!errTxts.parent_phone?.length}
											helperText={errTxts.parent_phone}
										/>
										<TextField
											onChange={handleChange}
											name="parent_email"
											label="Email Address*"
											id="email-parent"
											error={!!errTxts.parent_email?.length}
											helperText={errTxts.parent_email}
										/>
									</fieldset>
								</>
							) : form.isNew === 2 ? (
								<fieldset>
									<div className="grid md:grid-cols-2 grid-cols-1 gap-x-40 gap-y-20">
										<FormControl variant="standard">
											<InputLabel shrink id="selectLabel">
												Select*
											</InputLabel>
											<Select
												defaultValue="parent"
												labelId="selectLabel"
												id="Select"
												label="Select*"
											>
												<MenuItem value="parent">
													<span id="parent">Parent</span>
												</MenuItem>
											</Select>
										</FormControl>
										<Autocomplete
											id="parent-autocpmplete"
											options={existingParents}
											renderOption={(option) => (
												<>
													<div
														className="flex"
														id={option.id}
														key={option.id}
														style={{ gap: 10 }}
													>
														<Avatar src={option.photo} />
														<div>
															<div>{option.name}</div>
															<div>{option.email}</div>
														</div>
													</div>
												</>
											)}
											getOptionLabel={(option) => option.name}
											loading={loadingParents}
											clearOnBlur={false}
											autoComplete={false}
											loadingText="Loading..."
											onChange={(e, v) => setForm({ ...form, parent_id: v?.id })}
											renderInput={(params) => (
												<TextField
													{...params}
													onChange={(e) => setParentEmail(e.target.value)}
													label="Contacts Name"
													helperText={errTxts.parent_id}
													error={!!errTxts.parent_id?.length}
													autoComplete="off"
													id="parent-name"
												/>
											)}
										/>
									</div>
								</fieldset>
							) : (
								''
							)}
						</fieldset>
					</div>
					<div className="flex justify-center" style={{ marginTop: '60px' }}>
						{!isLoading ? (
							<div className=" center-btn">
								<CustomButton
									variant="secondary"
									width="140px"
									onClick={() => {
										history.goBack();
									}}
								>
									Cancel
								</CustomButton>

								<CustomButton variant="primary" type="submit" width="140px" fontSize="15px" id="submit">
									Submit
								</CustomButton>
							</div>
						) : (
							<div className="flex justify-center">
								<CircularProgress className="mx-auto" />
							</div>
						)}
					</div>
				</form>
			</div>
		</div>
	);
}

export default EnrollStudents;
