/* eslint-disable consistent-return */
import FuseAnimate from '@/@fuse/core/FuseAnimate';
import history from '@/@history';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import PageHeader from '@/app/customComponents/PageHeader';
import { getRooms } from '@/app/services/liveStreaming/liveStreaming';
import * as Actions from '@/app/store/actions';
import {
	CircularProgress,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Info } from '@material-ui/icons';
import Close from '@material-ui/icons/Close';
import Pagination from '@material-ui/lab/Pagination';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Bigplus from './BigPlus';
import BlurInfoDialog from './BlurInfoDialog';
import BlurStreaming from './BlurStreaming';
import Streaming from './Streaming';

const useStyles = makeStyles((theme) => ({
	content: {
		position: 'relative',
		display: 'flex',
		overflow: 'auto',
		flex: '1 1 auto',
		flexDirection: 'column',
		width: '100%',
		'-webkit-overflow-scrolling': 'touch',
		zIndex: 2,
	},
}));
function Live({ blur }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [rooms, setRooms] = useState([]);
	const [loading, setLoading] = useState(true);
	const [search, setSearch] = useState('');
	const [status, setStatus] = useState('');
	const [page, setPage] = useState(1);
	const [firstLoad, setFirstLoad] = useState(true);
	const [disablePagination, setDisablePagination] = useState(false);
	const [totalPages, setTotalPages] = useState(0);
	const [refresh, setRefresh] = useState(false);
	const goToCameraRegistrationForm = () => {
		history.push('/livestreaming-CameraRegistration');
	};
	useEffect(() => {
		let isMounted = true;
		setLoading(true);
		getRooms(search, status, page)
			.then((res) => {
				if (!isMounted) return;
				setRooms(res.data.data);
				setTotalPages(res.data.last_page);
			})
			.catch((err) => {
				if (!isMounted) return;
				console.log(err);
			})
			.finally(() => {
				if (!isMounted) return;
				setLoading(false);
				setFirstLoad(false);
			});
		return () => {
			isMounted = false;
		};
	}, [refresh]);

	useEffect(() => {
		let isMounted = true;
		if (firstLoad) {
			return;
		}
		const timeout = setTimeout(() => {
			setLoading(true);
			getRooms(search, status, 1)
				.then((res) => {
					if (!isMounted) return;
					setRooms(res.data.data);
					setPage(1);
					setTotalPages(res.data.last_page);
				})
				.catch((err) => {
					if (!isMounted) return;
					console.log(err);
				})
				.finally(() => {
					if (!isMounted) return;
					setLoading(false);
				});
		}, 1000);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [search, status]);

	useEffect(() => {
		let isMounted = true;
		if (firstLoad) {
			return;
		}
		setDisablePagination(true);
		getRooms(search, status, page)
			.then((res) => {
				if (!isMounted) return;
				setRooms(res.data.data);
			})
			.catch((err) => {
				if (!isMounted) return;
				console.log(err);
			})
			.finally(() => {
				if (!isMounted) return;
				setDisablePagination(false);
			});

		return () => {
			isMounted = false;
		};
	}, [page]);

	const showDialog = () => {
		dispatch(
			Actions.openDialog({
				children: <BlurInfoDialog />,
			})
		);
	};

	return (
		<>
			<FuseAnimate animation="transition.slideLeftIn" duration={600}>
				<div className="w-full">
					<div className="flex justify-between w-full">
						<div className="flex flex-col w-full">
							<div className="flex ">
								<PageHeader title="Live Streaming" hasBack={false} />
								{blur && (
									<IconButton onClick={showDialog} className="p-6 px-12">
										<Info />
									</IconButton>
								)}
							</div>
							<div className="flex flex-col sm:flex-row w-full gap-16 justify-end">
								{!blur && (
									<div className="sm:order-3 min-w-160 self-end">
										<CustomButton
											variant="primary"
											height={33}
											width={'100%'}
											onClick={goToCameraRegistrationForm}
										>
											Camera Registration
										</CustomButton>
									</div>
								)}
								<TextField
									style={{
										alignSelf: 'center',
									}}
									id="search-input"
									className={blur ? 'w-full md:w-1/4' : 'sm:w-1/2 md:w-1/4'}
									fullWidth
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									label="Search By Name"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													id="search-icon"
													onClick={() => {
														document.getElementById('search-input').focus();
													}}
												>
													<img
														alt="search-icon"
														src="assets/images/search-icon.svg"
														height="80%"
														width="80%"
													/>
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<FormControl
									fullWidth
									variant="standard"
									className={blur ? 'w-full md:w-1/4' : 'w-full sm:w-1/4'}
								>
									<InputLabel id="status-label">Filters</InputLabel>
									<Select
										value={status}
										onChange={(e) => setStatus(e.target.value)}
										name="status"
										id="status"
										labelId="status-label"
										label="Filters"
										endAdornment={
											status ? (
												<IconButton
													id="clear-room-filter"
													size="small"
													className="mr-16"
													onClick={() => {
														setStatus('');
													}}
												>
													<Close fontSize="small" />
												</IconButton>
											) : (
												''
											)
										}
									>
										<MenuItem value={1}>
											<span id="active"> Active</span>
										</MenuItem>
										<MenuItem value={2}>
											<span id="inactive"> Inactive</span>
										</MenuItem>
									</Select>
								</FormControl>
							</div>
						</div>
					</div>
					<Paper className="p-16 mt-28 sm:px-20 shadow-card rounded-8">
						<div className="  gap-10  md:gap-32 sm:grid sm:grid-cols-2 justify-center items-center">
							{loading && (
								<div className="py-64 flex justify-center col-span-2">
									<CircularProgress size={35} />
								</div>
							)}
							{!loading &&
								rooms.map((room) => {
									return (
										<div className="pb-20 " key={room.id}>
											{blur ? (
												<BlurStreaming room={room} key={room.id} setRefresh={setRefresh} />
											) : (
												<Streaming room={room} key={room.id} />
											)}
										</div>
									);
								})}

							{!loading &&
								!blur &&
								rooms.length > 0 &&
								Array.from(Array(4 - rooms.length)).map((_a, i) => {
									const id = `bigplus-${i}`;
									return <Bigplus key={id} />;
								})}

							{!loading && !rooms.length && (
								<div className="py-64 flex justify-center col-span-2 text-pd-xl">
									No records available{' '}
								</div>
							)}
						</div>
						{!firstLoad && rooms.length > 0 && (
							<div className=" flex justify-center  py-16">
								<Pagination
									color="primary"
									count={totalPages}
									page={page}
									onChange={(e, p) => setPage(p)}
									disabled={disablePagination}
									classes={{ ul: 'custom-pagination-ul' }}
								/>
							</div>
						)}
					</Paper>
				</div>
			</FuseAnimate>
		</>
	);
}

export default Live;
