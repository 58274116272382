import { Badge, Button, Typography } from '@material-ui/core';

const TopNavButton = ({ onClick, badgeContent, icon, title }) => {
	return badgeContent ? (
		<Button
			onClick={onClick}
			className="px-0 w-72 hover:shadow hover:bg-transparent"
			style={{ minWidth: 'max-content' }}
		>
			<Badge
				badgeContent={badgeContent}
				className=""
				color="error"
				overlap="circular"
				invisible={badgeContent === 0}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<div className="flex flex-col items-center justify-center gap-0">
					<div className="flex justify-center">{icon}</div>
					<Typography variant="body1" className="font-bold normal-case text-pd-xs ">
						{title}
					</Typography>
				</div>
			</Badge>
		</Button>
	) : (
		<Button
			onClick={onClick}
			className="px-0 w-72 hover:shadow hover:bg-transparent"
			style={{ minWidth: 'max-content' }}
		>
			<div className="flex flex-col items-center justify-center gap-0">
				<div className="w-32 sm:w-full flex justify-center">{icon}</div>
				<Typography variant="body1" className="font-bold normal-case text-pd-xs ">
					{title}
				</Typography>
			</div>
		</Button>
	);
};

export default TopNavButton;
