import React from 'react';

function CustomButton({
	onClick,
	type,
	height,
	width,
	fontSize,
	children,
	disabled,
	padding,
	textAlign,
	marginTop,
	background,
	style,
	marginLeft,
	marginRight,
	id,
	maxWidth,
	minWidth,
	...props
}) {
	return (
		<button
			className={`add-btn hover:shadow-blue-md cursor-pointertext-center rounded-[23px] font-semibold w-[100px] h-[35px] text-[13px] border-[1px] text-pd-blue border-pd-blue transition-shadow duration-200 disabled:cursor-not-allowed hover:bg-blue-500 hover:text-white hover:bg-pd-blue-gradient disabled:hover:bg-grey-200 disabled:hover:opacity-70 hover:border-none`}
			style={{
				...style,
				height,
				width,
				fontSize,
				padding,
				textAlign,
				marginTop,
				background,
				marginLeft,
				marginRight,
				maxWidth,
				minWidth,
			}}
			type={type || 'button'}
			disabled={disabled}
			onClick={onClick}
			id={id}
			{...props}
		>
			{children}
		</button>
	);
}

export default CustomButton;
