/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
import history from '@/@history';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import CustomDatePicker from '@/app/customComponents/CustomDatePicker/CustomDatePicker';
import { uploadFile } from '@/app/services/imageUpload/imageUpload';
import { getSearchableCityList, getSearchableStateList } from '@/app/services/schools/schools';
import { getCountryList } from '@/app/services/settings/settings';
import { getStudent, updateStudent } from '@/app/services/students/students';
import * as Actions from '@/app/store/actions';
import { getImageUrl, isImageFile, isInValidName } from '@/utils/utils';
import {
	Avatar,
	CircularProgress,
	FormControl,
	FormHelperText,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
	content: {
		position: 'relative',
		display: 'flex',
		overflow: 'auto',
		flex: '1 1 auto',
		flexDirection: 'column',
		width: '100%',
		'-webkit-overflow-scrolling': 'touch',
		zIndex: 2,
	},
}));

function EditStudent() {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { id } = useParams();

	useEffect(() => {
		if (!history.location.state?.student && !id) {
			goBack();
		}
	}, [history.location.state?.student]);

	useEffect(() => {
		let isMounted = true;
		if (!history.location.state?.student && id) {
			setIsLoading(true);
			getStudent(id)
				.then((res) => {
					if (!isMounted) return;
					setModifiedStudent({
						...res.data,
						address1: res.data?.address1 || '',
						address2: res.data?.address2 || '',
						notes: res.data?.notes || '',
						country_code: res.data?.country?.code || '',
						state_id: res.data?.state?.id || '',
						city: res.data?.city?.name || '',
						zip_code: res.data?.zip_code || '',
						country_id: res.data?.country?.id || '',
					});
					setPreview(res.data?.photo);
					setDefaultCity(res.data?.city);
					setDefaultState(res.data?.state);
					setIsLoading(false);
				})
				.catch((err, ...rest) => {
					if (!isMounted) return;
					if (err.response?.status === 404) {
						history.push('/students');
					}
					setIsLoading(false);
				});
		}
		return () => {
			isMounted = false;
		};
	}, [id]);

	const [modifiedStudent, setModifiedStudent] = useState({
		...history.location?.state?.student,
		address1: history.location?.state?.student?.address1 || '',
		address2: history.location?.state?.student?.address2 || '',
		notes: history.location?.state?.student?.notes || '',
		country_code: history.location?.state?.student?.country?.code || '',
		state_id: history.location?.state?.student?.state?.id || '',
		city: history.location?.state?.student?.city?.name || '',
		zip_code: history.location?.state?.student?.zip_code || '',
		country_id: history.location?.state?.student?.country?.id || '',
		doctor_name: history.location?.state?.student?.doctor?.name || '',
		doctor_phone: history.location?.state?.student?.doctor?.phone || '',
	});

	const [errTxts, setErrTxts] = useState({});
	const [selectedFile, setSelectedFile] = useState();
	const [preview, setPreview] = useState(history.location?.state?.student?.photo);
	const [isSaving, setIsSaving] = useState(false);
	const inputRef = useRef(null);
	const [date, setDate] = useState(modifiedStudent?.date_of_birth);
	const [isStateloading, setIsStateloading] = useState(false);
	const [searchStateQuery, setStateSearchQuery] = useState('');
	const [isCityLoading, setIsCityLoading] = useState(false);
	const [searchCityQuery, setSearchCityQuery] = useState('');
	const [defaultCity, setDefaultCity] = useState(history.location?.state?.student?.city);
	const [defaultState, setDefaultState] = useState(history.location?.state?.student?.state);
	const [countries, setCountries] = useState([]);
	const [city, setCity] = useState([]);
	const [states, setStates] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		let isMounted = true;
		getCountryList()
			.then((res) => {
				if (!isMounted) return;
				setCountries(res.data);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get countries.',
						autoHideDuration: 1500,
						variant: 'error',
					})
				);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(() => {
			setIsStateloading(true);
			setStates([]);
			setCity([]);
			if (!searchStateQuery && !modifiedStudent.country_code) {
				setIsStateloading(true);
				setStates([]);
			} else {
				getSearchableStateList(searchStateQuery, searchStateQuery ? undefined : 1)
					.then((res) => {
						if (!isMounted) return;
						setStates(res.data.data);
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to get states.',
								autoHideDuration: 1500,
								variant: 'error',
							})
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsStateloading(false);
					});
			}
		}, 1000);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [dispatch, modifiedStudent.country_code, searchStateQuery]);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(() => {
			setIsCityLoading(true);

			if (!searchCityQuery && !modifiedStudent.state_id) {
				setCity([]);
			} else {
				getSearchableCityList(modifiedStudent.state_id, searchCityQuery, 1)
					.then((res) => {
						if (!isMounted) return;
						setCity(res.data.data);
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to get cities.',
								autoHideDuration: 1500,
								variant: 'error',
							})
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsCityLoading(false);
					});
			}
		}, 1000);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [modifiedStudent.state_id, searchCityQuery]);

	useEffect(() => {
		if (date) {
			if (dayjs(date).format('YYYY-MM-DD') === modifiedStudent?.date_of_birth) {
				return;
			}
			setModifiedStudent({ ...modifiedStudent, date_of_birth: dayjs(date).format('YYYY-MM-DD') });
		} else {
			setModifiedStudent({ ...modifiedStudent, date_of_birth: '' });
		}
	}, [date, modifiedStudent?.date_of_birth]);

	useEffect(() => {
		if (!selectedFile) {
			setPreview(history.location?.state?.student?.photo);
			return;
		}

		const objectUrl = URL.createObjectURL(selectedFile);
		setPreview(objectUrl);

		return () => URL.revokeObjectURL(objectUrl);
	}, [selectedFile]);

	const onSelectFile = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(null);
			return;
		}
		const file = e.target.files[0];
		if (!isImageFile(file)) {
			dispatch(Actions.showMessage({ message: 'Please select an image file', variant: 'error' }));
		} else {
			setSelectedFile(e.target.files[0]);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setErrTxts({ ...errTxts, [name]: '' });
		if (name === 'country_code') {
			setModifiedStudent({ ...modifiedStudent, country_code: value, country_id: value });
			return;
		}
		if (name === 'zip_code') {
			// Only Number, Max 5, can be empty
			if (value && !/^[0-9]{0,5}$/.test(value)) {
				return;
			}
		}
		setModifiedStudent({ ...modifiedStudent, [name]: value });
	};

	const handleSubmit = () => {
		if (!modifiedStudent.first_name) {
			setErrTxts({ ...errTxts, first_name: 'This field is required' });
			return;
		}
		if (modifiedStudent.first_name && isInValidName(modifiedStudent.first_name)) {
			setErrTxts({ ...errTxts, first_name: 'Please enter a valid name.' });
			return;
		}
		modifiedStudent.first_name = modifiedStudent.first_name.trim();
		if (!modifiedStudent.last_name) {
			setErrTxts({ ...errTxts, last_name: 'This field is required' });
			return;
		}
		if (modifiedStudent.last_name && isInValidName(modifiedStudent.last_name)) {
			setErrTxts({ ...errTxts, last_name: 'Please enter a valid name.' });
			return;
		}
		modifiedStudent.last_name = modifiedStudent.last_name.trim();
		if (!modifiedStudent.address1) {
			setErrTxts({ ...errTxts, address1: 'This field is required' });
			return;
		}
		if (!modifiedStudent.date_of_birth) {
			setErrTxts({ ...errTxts, date_of_birth: 'This field is required' });
			return;
		}
		if (!modifiedStudent.country_code) {
			setErrTxts({ ...errTxts, country_code: 'This field is required' });
			return;
		}
		if (!modifiedStudent.state_id) {
			setErrTxts({ ...errTxts, state_id: 'This field is required' });
			return;
		}
		if (!modifiedStudent.city) {
			setErrTxts({ ...errTxts, city: 'This field is required' });
			return;
		}
		if (!modifiedStudent.zip_code) {
			setErrTxts({ ...errTxts, zip_code: 'This field is required' });
			return;
		}

		delete modifiedStudent.notes;

		if (selectedFile) {
			const filename = getImageUrl(selectedFile);
			setIsSaving(true);
			uploadFile(selectedFile, filename)
				.then((response) => {
					modifiedStudent.photo = `${import.meta.env.VITE_S3_BASE_URL}${response}`;
					modifiedStudent.thumb = `${import.meta.env.VITE_S3_BASE_URL}${response}`;
					updateStudent(modifiedStudent.id, modifiedStudent)
						.then((resp) => {
							dispatch(
								Actions.showMessage({
									message: resp.data.message,
									autoHideDuration: 1500,
									variant: 'success',
								})
							);
							goBack();
						})
						.catch(handleUpdateError)
						.finally(() => setIsSaving(false));
				})
				.catch((err) => {
					dispatch(
						Actions.showMessage({
							message: 'Failed to upload image',
							autoHideDuration: 1500,
							variant: 'error',
						})
					);
					setIsSaving(false);
				});
		} else {
			setIsSaving(true);
			updateStudent(modifiedStudent.id, modifiedStudent)
				.then((resp) => {
					dispatch(
						Actions.showMessage({
							message: resp.data.message,
							autoHideDuration: 1500,
							variant: 'success',
						})
					);
					goBack();
				})
				.catch(handleUpdateError)
				.finally(() => setIsSaving(false));
		}
	};

	const handleUpdateError = (err) => {
		if (err.response?.data?.errors) {
			setErrTxts(err.response.data.errors);
		} else {
			dispatch(
				Actions.showMessage({
					message: 'Failed to edit student information.',
					autoHideDuration: 1500,
					variant: 'error',
				})
			);
		}
	};

	const goBack = () => {
		if (history.location?.state?.token) {
			history.push(`studentform?token=${history.location?.state?.token}`, { tab: 'personal' });
		} else {
			history.goBack();
		}
	};

	return (
		<>
			<div className="P-m w-9/12 mx-auto mb-64">
				<div className="stdinfo  mx-auto">
					<span className="self-end font-extrabold ">
						<h1 className="md:text-pd-lg lg:text-pd-xl self-end font-extrabold head-css">
							{' '}
							<span className="mr-12 icon-color">
								<IconButton onClick={goBack}>
									<img
										src="assets/images/arrow-long.png"
										alt="filter"
										width="24px"
										className="fiterimg"
									/>
								</IconButton>
							</span>{' '}
							<span className="md:text-pd-lg lg:text-pd-xl self-end font-extrabold">
								Edit Personal Information
							</span>
						</h1>
					</span>
				</div>

				<div className="">
					<div className="bg-white flex flex-col justify-between gap-32 items-center">
						<div
							className={`flex justify-between student-edit ${isLoading ? 'w-full' : ''}`}
							style={{
								padding: '50px 80px 0px 20px',
							}}
						>
							<div
								className="relative pic-upload-overlay cursor-pointer mx-auto sm:mx-40"
								onClick={() => inputRef.current.click()}
							>
								<Avatar
									style={{ height: '140px', width: '140px' }}
									src={preview}
									className="imageupload"
								/>
								<div className="pp-input-overlay">
									<i className="fa fa-2x fa-camera" />
								</div>
								<input
									onChange={onSelectFile}
									type="file"
									name="image"
									id="image"
									className="hidden"
									accept="image/*"
									ref={inputRef}
								/>
							</div>
							{isLoading ? (
								<div className="flex justify-center items-center flex-grow">
									<CircularProgress />
								</div>
							) : (
								<div className="grid grid-cols-1 sm:grid-cols-3 gap-32 mt-32">
									<TextField
										helperText={errTxts.first_name}
										error={errTxts.first_name}
										onChange={handleChange}
										value={modifiedStudent.first_name}
										name="first_name"
										label="First Name"
										required
									/>
									<TextField
										helperText={errTxts.last_name}
										error={errTxts.last_name}
										onChange={handleChange}
										value={modifiedStudent.last_name}
										name="last_name"
										label="Last Name"
										required
									/>
									<CustomDatePicker
										errTxts={errTxts?.date_of_birth}
										value={modifiedStudent?.date_of_birth}
										name="date_of_birth"
										setValue={(d) => {
											setDate(d);
											setErrTxts({ ...errTxts, date_of_birth: '' });
										}}
										label="Date of Birth"
										required
										disableFuture
									/>
									<FormControl error={errTxts.gender?.length} variant="standard" required>
										<InputLabel id="relationLabel">Gender</InputLabel>
										<Select
											onChange={handleChange}
											labelId="relationLabel"
											id="childRelation"
											label="Gender"
											name="gender"
											defaultValue={modifiedStudent.gender}
										>
											<MenuItem value="male">Male</MenuItem>
											<MenuItem value="female">Female</MenuItem>
										</Select>
										{errTxts.gender && <FormHelperText>{errTxts.gender}</FormHelperText>}
									</FormControl>
									<TextField
										helperText={errTxts.address1}
										error={errTxts.address1}
										onChange={handleChange}
										value={modifiedStudent.address1}
										required
										name="address1"
										label="Address"
									/>
									<FormControl error={errTxts.country_code}>
										<TextField
											name="country_code"
											select
											label="Country"
											value={modifiedStudent.country_id}
											onChange={handleChange}
											required
											labelId="country-label"
											style={{ width: '100%' }}
											id="country_code"
											error={errTxts.country_code}
										>
											{countries?.length ? (
												countries.map((country) => {
													return (
														<MenuItem key={country.id} value={country.id}>
															{country.name}
														</MenuItem>
													);
												})
											) : (
												<MenuItem>Loading...</MenuItem>
											)}
										</TextField>
										{errTxts.country_code && (
											<FormHelperText>{errTxts.country_code}</FormHelperText>
										)}
									</FormControl>
									<Autocomplete
										id="state-autocomplete"
										name="state_id"
										options={states}
										renderOption={(option) => (
											<>
												<div className="flex" style={{ gap: 10 }}>
													<div>{option.name}</div>
												</div>
											</>
										)}
										getOptionLabel={(option) => option.name}
										autoComplete={false}
										clearOnBlur={false}
										disableClearable
										loading={isStateloading}
										loadingText="...Loading"
										sx={{ width: '100%' }}
										onChange={(_e, v) => {
											setDefaultState(v);
											setErrTxts({ ...errTxts, state_id: '' });
											setModifiedStudent({ ...modifiedStudent, state_id: v?.id || '' });
										}}
										onInputChange={(e, value) => {
											setStateSearchQuery(value);
											if (value === '') {
												setModifiedStudent({
													...modifiedStudent,
													state_id: '',
													city: '',
												});
												setDefaultCity({ ...defaultState, name: '' });
											}
										}}
										value={defaultState}
										renderInput={(params) => (
											<TextField
												{...params}
												label="State"
												required
												error={errTxts.state_id}
												helperText={errTxts.state_id}
												autoComplete="off"
												defaultValue={defaultState}
											/>
										)}
									/>
									<Autocomplete
										id="city"
										name="city"
										options={city}
										renderOption={(option) => (
											<>
												<div className="flex" style={{ gap: 10 }}>
													<div>{option.name}</div>
												</div>
											</>
										)}
										getOptionLabel={(option) => option.name}
										autoComplete={false}
										clearOnBlur={false}
										disableClearable
										loading={isCityLoading}
										loadingText="...Loading"
										sx={{ width: '100%' }}
										onChange={(_e, v) => {
											setDefaultCity(v);
											setErrTxts({ ...errTxts, city: '' });
											setModifiedStudent({ ...modifiedStudent, city: v?.id, city_id: v?.id });
										}}
										value={defaultCity}
										onInputChange={(e, value) => {
											setSearchCityQuery(value);
											if (value === '') {
												setModifiedStudent({
													...modifiedStudent,
													city: '',
													city_id: '',
												});
												setDefaultCity({ ...defaultCity, name: '' });
											}
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="City"
												required
												name="city"
												error={errTxts.city}
												helperText={errTxts.city}
												autoComplete="off"
												defaultValue={defaultCity}
											/>
										)}
									/>
									<TextField
										onChange={handleChange}
										className="text-field field-width"
										value={modifiedStudent.zip_code}
										name="zip_code"
										required
										label="Zip Code"
										id="zip_code"
										helperText={errTxts.zip_code}
										error={errTxts.zip_code}
									/>
								</div>
							)}
						</div>
						<div className="pb-32 flex gap-16">
							{!isSaving ? (
								<>
									<CustomButton variant="secondary" onClick={goBack}>
										Cancel
									</CustomButton>
									<CustomButton variant="primary" onClick={handleSubmit}>
										Update
									</CustomButton>
								</>
							) : (
								<div className="flex justify-center">
									<CircularProgress className="mx-auto" />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default EditStudent;
