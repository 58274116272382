/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import history from '@/@history';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import { changeCheckInCode, getStudent } from '@/app/services/students/students';
import { calculateDueOverDue, getAgeDetails } from '@/utils/utils';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, CircularProgress, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import './StudentInformation.css';

const useStyles = makeStyles((theme) => ({
	content: {
		position: 'relative',
		display: 'flex',
		overflow: 'auto',
		flex: '1 1 auto',
		flexDirection: 'column',
		width: '100%',
		'-webkit-overflow-scrolling': 'touch',
		zIndex: 2,
	},
}));

function StudentInformation() {
	const classes = useStyles();

	const dispatch = useDispatch();
	const params = useParams();
	const [student, setStudent] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [refresh, setRefresh] = useState(0);
	const [age, setAge] = useState('');
	const [due, setDue] = useState([]);
	const [overDue, setOverDue] = useState([]);
	const [hiddenCodes, setHiddenCodes] = useState([]);
	const [changeCodeLoader, setChangeCodeLoader] = useState([]);

	const CodeHidden = (res, bool) => {
		return res?.data?.parents
			.concat(res?.data?.approved_pickups)
			?.concat(res?.data?.extended_family)
			?.map((parent) => bool);
	};

	useEffect(() => {
		let isMounted = true;
		setIsLoading(true);
		getStudent(params?.id)
			.then((res) => {
				if (!isMounted) return;
				setStudent(res?.data);
				setHiddenCodes(CodeHidden(res, true));
				setChangeCodeLoader(CodeHidden(res, false));
				setIsLoading(false);
			})
			.catch((err, ...rest) => {
				if (!isMounted) return;
				if (err.response?.status === 404) {
					history.push('/students');
				}
				setIsLoading(false);
			});
		return () => {
			isMounted = false;
		};
	}, [refresh, params?.id]);

	useEffect(() => {
		if (student) {
			setAge(getAgeDetails(dayjs(student?.date_of_birth), dayjs()));
		}
	}, [student]);

	const handleCheckInCodeShowHide = (index) => {
		const temp = [...hiddenCodes];
		temp[index] = !hiddenCodes[index];
		setHiddenCodes(temp);
	};

	const goToAddEditContact = (edit, contact) => {
		history.push({ pathname: '/students-contact', state: { isEdit: edit, row: student, contact } });
	};

	useEffect(() => {
		if (student && age) {
			const [overDuedoses, duedoses] = calculateDueOverDue(student, age);
			setDue(duedoses);
			setOverDue(overDuedoses);
		}
	}, [age]);

	const changeCode = (parentId, index) => {
		if (index || index === 0) {
			const temp = [...changeCodeLoader];
			temp[index] = true;
			setChangeCodeLoader(temp);
		}
		changeCheckInCode(parentId)
			.then((resp) => {
				getStudent(params?.id)
					.then((res) => {
						setStudent(res.data);
					})
					.catch((err) => {
						console.log({ ...err });
					})
					.finally(() => {
						if (index || index === 0) {
							const temp = [...changeCodeLoader];
							temp[index] = false;
							setChangeCodeLoader(temp);
						}
					});
			})
			.catch((err) => {
				console.log({ ...err });
			});
	};

	return (
		<>
			<div className="P-m">
				<StudentView student={student} isLoading={isLoading} />
				<div className="P-m">
					<MedicalView student={student} isLoading={isLoading} />
				</div>

				<div className="P-m stdinfo flex justify-between mx-auto">
					<div style={{ width: '48%' }}>
						<div className="flex items-center flex-nowrap justify-between mx-auto mb-8">
							<span className="text-pd-2xl self-end font-extrabold ">
								<h1 className="hd-main">Rooms</h1>
							</span>
							<div className="flex justify-between">
								<span className="mx-4">
									<CustomButton
										variant="secondary"
										height="46"
										width="100px"
										fontSize="15px"
										disabled={isLoading}
										onClick={() => handleHomeRoomDialog(student)}
									>
										<FontAwesomeIcon icon={faEdit} />
										<span> Edit </span>
									</CustomButton>
								</span>
							</div>
						</div>
						<div className="flex items-center flex-nowrap  mx-auto bg-white p-32">
							{isLoading ? (
								<div className="flex align-center justify-center">
									<CircularProgress size={35} />
								</div>
							) : (
								<div className="room-detail">
									<h4>Homeroom</h4>
									<div className="text-pd-2xl mt-2 font-bold capitalize" style={{ fontSize: 17 }}>
										{student?.home_room?.name || 'No Homeroom set'}
									</div>
								</div>
							)}
						</div>
					</div>
					<div style={{ width: '48%' }}>
						<div className="flex items-center flex-nowrap justify-between mx-auto mb-8">
							<span className="text-pd-2xl self-end font-extrabold ">
								<h1 className="hd-main">Official Documents</h1>
							</span>
							<span>
								<div>
									<CustomButton
										variant="secondary"
										height="46"
										width="180px"
										fontSize="15px"
										disabled={isLoading}
										onClick={openAttachmentModal}
									>
										<FontAwesomeIcon icon={faPlus} />
										<span className="addstd"> Add an attachment </span>
									</CustomButton>
								</div>
							</span>
						</div>
						<div
							className={`flex flex-col mx-auto bg-white ${isFileLoading && 'justify-center'}`}
							style={{ minHeight: '110px' }}
						>
							{isLoading || isFileLoading ? (
								<div className="flex align-center justify-center">
									<CircularProgress size={35} />
								</div>
							) : (
								<>
									<div>
										<div className="bg-blue-100 px-40 py-12 flex justify-between">
											<div className="font-bold med-table-headers w-1/4">Category</div>
											<div className="font-bold med-table-headers w-1/4">File Name</div>
											<div className="font-bold med-table-headers w-1/4">Expiry Date</div>
											<div className="font-bold med-table-headers w-1/4">Attachment</div>
										</div>
										<div style={{ maxHeight: 300, overflow: 'auto' }}>
											{student?.attachments.length ? (
												student?.attachments?.map((attachment) => {
													return (
														<>
															<div
																className="mx-8 px-32 py-8 flex justify-between items-center"
																style={{ gap: 20 }}
															>
																<div className="w-1/4 truncate">
																	{attachment?.category}
																</div>
																<div className="w-1/4 truncate">{attachment?.name}</div>
																<div className="w-1/4 truncate">
																	{attachment?.expiry_date ? (
																		<div className="w-1/4">
																			{dayjs(
																				new Date(attachment?.expiry_date)
																			).format('MMM DD, YYYY')}
																		</div>
																	) : (
																		<div className="w-1/4 pick-center">-</div>
																	)}
																</div>
																<div className="w-1/4 relative mt-10">
																	<a
																		href={attachment.file}
																		target="_blank"
																		rel="noopener noreferrer"
																	>
																		<img
																			style={{
																				width: '30px',
																				marginTop: '-5px',
																			}}
																			src="assets/images/pdf_thumbnail.png"
																			className="cursor-pointer mx-auto"
																			alt="file"
																		/>
																	</a>
																	<div
																		className="remove-attachment"
																		onClick={() =>
																			handleRemoveAttachment(attachment.id)
																		}
																	>
																		x
																	</div>
																</div>
															</div>
															<hr className="mx-8" style={{ color: 'lightgrey' }} />
														</>
													);
												})
											) : (
												<div className="flex justify-center mt-20 font-bold text-pd-lg">
													No Attachment
												</div>
											)}
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
				<div className="contact-detail">
					<div className="stdinfo flex items-center flex-nowrap justify-between mx-auto">
						<span className="text-pd-2xl self-end">
							<h1 className="hd-main">Contacts</h1>
						</span>
						<div className="personal-button flex justify-between">
							<span className="mx-4 addplus">
								<CustomButton
									variant="secondary"
									height="46"
									width="174px"
									fontSize="15px"
									disabled={isLoading}
									onClick={() => goToAddEditContact(0)}
								>
									<FontAwesomeIcon icon={faPlus} />
									<span className="addstd"> Add a Contact </span>
								</CustomButton>
							</span>
						</div>
					</div>
					<div className="contact-table">
						<TableContainer>
							<Table stickyHeader className={` table-1366x657`} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell className="bg-white school-table-header" style={{ width: '15%' }}>
											Contact
										</TableCell>
										<TableCell className="bg-white school-table-header" style={{ width: '15%' }}>
											Email
										</TableCell>
										<TableCell className="bg-white school-table-header" style={{ width: '15%' }}>
											Phone
										</TableCell>
										<TableCell className="bg-white school-table-header" style={{ width: '15%' }}>
											Emergency Contact
										</TableCell>
										<TableCell
											className="bg-white school-table-header"
											style={{ width: '10%' }}
											align="center"
										>
											Can pickup
										</TableCell>
										<TableCell
											className="bg-white school-table-header action-div"
											style={{ width: '23%' }}
										>
											Checkin code
										</TableCell>
										{/* <TableCell className="bg-white" style={{ width: '10%' }} align="center" /> */}
										<TableCell
											className="bg-white school-table-header action-div"
											style={{ width: '7%' }}
										>
											Action
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{isLoading ? (
										<TableRow>
											<TableCell align="center" colSpan={8}>
												<CircularProgress size={35} />
											</TableCell>
										</TableRow>
									) : (
										<>
											{student?.parents
												.concat(student?.approved_pickups)
												.concat(student?.extended_family)
												?.map((familie, index) => (
													<TableRow key={familie?.id}>
														<TableCell style={{ fontWeight: 700 }}>
															<div className="grid grid-cols-2 auto-col-min auto-row-min">
																<div className="flex items-center">
																	<Avatar className="mr-4" src={familie?.photo} />
																	<div className="flex flex-col items-center">
																		<div className="parent-name truncate ">
																			{familie?.first_name} {familie?.last_name}
																		</div>
																		<div className="parent-relation self-start truncate std-parent">
																			{familie?.relation_with_child}
																		</div>
																		<div
																			className={`self-start truncate ${
																				familie?.is_app_access
																					? 'app-access'
																					: 'no-app-access'
																			} `}
																		>
																			{familie?.is_app_access
																				? 'App Access'
																				: 'No App Access'}
																		</div>
																	</div>
																</div>
															</div>
														</TableCell>
														<TableCell className="break-all">{familie?.email}</TableCell>
														<TableCell>{familie?.phone}</TableCell>
														<TableCell>
															{familie?.emergency_contact === true ? 'Yes' : 'No'}
														</TableCell>
														<TableCell className="pick-center">
															{familie?.can_pickup ? 'Yes' : 'No'}
														</TableCell>
														<TableCell>
															<div className="flex items-center">
																{hiddenCodes[index]
																	? familie?.parent_schools
																		? familie?.parent_schools[0]?.checkin_code
																				?.split('')
																				?.map(() => '*')
																				?.join('')
																		: 'N/A'
																	: familie?.parent_schools[0]?.checkin_code}
																{familie?.parent_schools ? (
																	<IconButton
																		onClick={() => handleCheckInCodeShowHide(index)}
																	>
																		{hiddenCodes[index] ? (
																			<VisibilityOffOutlined />
																		) : (
																			<VisibilityOutlined />
																		)}
																	</IconButton>
																) : null}
																<div className="flex justify-center">
																	{familie?.parent_schools ? (
																		familie?.parent_schools[0]
																			?.checkin_code_request ? (
																			changeCodeLoader[index] ? (
																				<div className="flex align-center justify-center">
																					<CircularProgress size={35} />
																				</div>
																			) : (
																				<CustomButton
																					variant="primary"
																					type="submit"
																					width="140px"
																					height="30px"
																					fontSize="11px"
																					onClick={() =>
																						changeCode(familie?.id, index)
																					}
																				>
																					Change Checkin Code
																				</CustomButton>
																			)
																		) : (
																			<span />
																		)
																	) : (
																		<span />
																	)}
																</div>
															</div>
														</TableCell>
														<TableCell component="th" scope="row">
															<div className="flex justify-between items-end">
																<IconButton
																	size="small"
																	onClick={() => goToAddEditContact(1, familie)}
																>
																	<img
																		src="assets/images/circle-edit.png"
																		alt="edit"
																		width="25px"
																	/>
																</IconButton>
																{familie?.verified_at === null ? (
																	<IconButton
																		size="small"
																		onClick={() => handledelete(familie)}
																	>
																		<img
																			src="assets/images/dlt.png"
																			alt="edit"
																			width="25px"
																		/>
																	</IconButton>
																) : (
																	<></>
																)}
															</div>
														</TableCell>
													</TableRow>
												))}
										</>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				</div>
				<div className="P-m">
					<div className="immunization  items-center flex-nowrap  mx-auto">
						<h4 className="hd-main">Immunizations</h4>
						<p>
							Immunizations tracks past student immunization dates and calculates overdue and due vaccines
							based on CDC recommendations.
						</p>
						<div className="flex items-start">
							<div className="box-imm flex items-center flex-nowrap justify-between mx-auto mr-44">
								<h3 className="space-btm hd-two">CDC Recommendations</h3>
								<div className="recomd-imm">
									<div className="flex mb-10" style={{ gap: 30 }}>
										<div className="font-bold mr-10 mb-4">
											<span className="bg-red rounded-full py-4 px-12 text-white font-extrabold mr-4">
												!
											</span>
											{overDue.filter((item) => item.count !== 0).length} Overdue
										</div>
										<div>
											<p className="px-16 ml-32" style={{ paddingTop: 0, paddingBottom: 10 }}>
												{overDue.map((item) => {
													if (item.count !== 0) {
														return (
															<>
																{item.name}: {item.count}
																{item.count === 1 ? ' dose ' : ' doses '} over due
																<br />
															</>
														);
													}
													return '';
												})}
												{overDue.filter((item) => item.count !== 0).length === 0
													? 'No over due doses'
													: ''}
											</p>
										</div>
									</div>
									<div className="flex mt-10" style={{ gap: 63 }}>
										<div className="font-bold mr-10">
											<span className="bg-green rounded-full py-4 px-6 text-white font-extrabold mr-4">
												<i className="fas fa-check" />
											</span>
											{due.length} Due
										</div>
										<div>
											<p className="px-16 ml-32" style={{ paddingTop: 0, paddingBottom: 10 }}>
												{due.map((item) => (
													<>
														{item}
														<br />
													</>
												))}
												{due.length === 0 ? 'No dose due' : ''}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="box-imm flex items-center flex-nowrap justify-between mx-auto">
								<Notes student={student} setRefresh={setRefresh} isLoading={isLoading} />
							</div>
						</div>
					</div>
				</div>
				<ImmunizationView
					isLoading={isLoading}
					student={student}
					age={age}
					refresh={refresh}
					setRefresh={setRefresh}
				/>
			</div>
		</>
	);
}

export default StudentInformation;
