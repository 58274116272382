/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import history from '@/@history';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import CustomPhoneInput from '@/app/customComponents/CustomPhoneInput/CustomPhoneInput';
import PageHeader from '@/app/customComponents/PageHeader';
import { createContact, updateContact } from '@/app/services/contacts/contacts';
import { uploadFile } from '@/app/services/imageUpload/imageUpload';
import { getRelations, preFetchData } from '@/app/services/students/students';
import * as Actions from '@/app/store/actions';
import { getImageUrl, isImageFile, isInValidName } from '@/utils/utils';
import {
	Avatar,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	IconButton,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from '@material-ui/core/';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { isPossiblePhoneNumber } from 'libphonenumber-js/min';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	content: {
		position: 'relative',
		display: 'flex',
		overflow: 'auto',
		flex: '1 1 auto',
		flexDirection: 'column',
		width: '100%',
		'-webkit-overflow-scrolling': 'touch',
		zIndex: 2,
	},
}));

function AddEditContact() {
	const classes = useStyles();
	const token = history.location?.state?.token;
	const dispatch = useDispatch();
	useEffect(() => {
		if (!history.location.state?.row) {
			history.goBack();
		}
	}, [history.location.state?.row]);
	const { contact, row, isEdit, allParents } = history.location.state;
	const [relations, setRelations] = useState([]);
	const [form, setForm] = useState(
		contact?.id
			? { ...contact, student_id: row.id, relation_id: contact.relation_id || 1 }
			: {
					student_id: row?.id,
					first_name: '',
					last_name: '',
					phone: '',
					email: '',
					is_primary_account: -1,
					can_pickup: -1,
					emergency_contact: -1,
					accees_given_by: -1,
			  }
	);
	const [errTxts, setErrTxts] = useState({});
	const [isAdding, setIsAdding] = useState(false);
	const [selectedFile, setSelectedFile] = useState();
	const [preview, setPreview] = useState();
	// App access field value is initially determined by form.is_app_access_label. Once the field is changed from this form, it is determined by form.accees_given_by
	const [grantedAppAccessChanged, setGrantedAppAccessChanged] = useState(false);
	const inputRef = useRef(null);

	useEffect(() => {
		if (!selectedFile) {
			setPreview(contact?.photo || null);
			return;
		}

		const objectUrl = URL.createObjectURL(selectedFile);
		setPreview(objectUrl);

		return () => URL.revokeObjectURL(objectUrl);
	}, [selectedFile, contact?.photo]);

	const onSelectFile = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(contact?.photo || null);
			return;
		}
		const file = e.target.files[0];
		if (!isImageFile(file)) {
			dispatch(Actions.showMessage({ message: 'Please select an image file', variant: 'error' }));
		} else {
			setSelectedFile(e.target.files[0]);
		}
	};

	useEffect(() => {
		let isMounted = true;
		getRelations().then((res) => {
			if (isMounted) {
				setRelations(res.data);
			}
		});

		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		let isMounted = true;
		if (!form?.email || isEdit) return;
		const fetchData = async () => {
			// Make your API call here
			// if email doesnt exist or invalid email is entered, dont call api
			if (!form?.email) return;
			if (!/^\S+@\S+\.\S+$/.test(form.email)) return;
			const res = await preFetchData(form?.email.trim());

			if (res?.data) {
				if (!isMounted) return;
				setForm((prevForm) => ({
					...prevForm,
					first_name: res.data.first_name,
					last_name: res.data.last_name,
					phone: res.data.phone,
				}));
				setPreview(res?.data?.photo);
			}
		};

		// Create a debounced function with a delay of 1000ms
		const debouncedFetchData = debounce(fetchData, 1000);

		// Call the debounced function every time form.email changes
		debouncedFetchData();

		// Cleanup function to cancel the debounced function
		return () => {
			isMounted = false;
			debouncedFetchData.cancel();
		};
	}, [form?.email]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setErrTxts({ ...errTxts, [name]: '' });
		setForm({ ...form, [name]: value });
	};

	const handleContactTypeChange = (e) => {
		const { value } = e.target;
		// Parents -- clear form.relation_with_child so it must be manually selected from select menu
		if (value === 1) {
			setForm({ ...form, relation_id: value, relation_with_child: 'Parent' });
		}
		// Approved Pickups -- clear form.relation_with_child so it must be manually updated
		// ineligible to be primary account holder, so clear that field as well
		if (value === 2) {
			setForm({ ...form, relation_id: value, relation_with_child: '', is_primary_account: 0 });
		}
		// Legal Guardian -- update form.relation_with_child to Legal Guardian from select menu
		if (value === 3) {
			setForm({ ...form, relation_id: value, relation_with_child: 'Legal Guardian' });
		}
		// Family -- clear form.relation_with_child so it must be manually updated
		// ineligible to be primary account holder, so clear that field as well
		if (value === 4) {
			setForm({ ...form, relation_id: value, relation_with_child: '', is_primary_account: 0 });
		}
	};

	const handleSubmit = () => {
		setErrTxts({});

		if (!form.first_name) {
			setErrTxts({ ...errTxts, first_name: 'This field is required' });
			return;
		}
		if (form.first_name && isInValidName(form.first_name)) {
			setErrTxts({ ...errTxts, first_name: 'Please enter a valid name.' });
			return;
		}
		form.first_name = form.first_name.trim();
		if (!form.last_name) {
			setErrTxts({ ...errTxts, last_name: 'This field is required' });
			return;
		}
		if (form.last_name && isInValidName(form.last_name)) {
			setErrTxts({ ...errTxts, last_name: 'Please enter a valid name.' });
			return;
		}
		form.last_name = form.last_name.trim();
		if (!form.relation_id) {
			setErrTxts({ ...errTxts, relation_id: 'This field is required' });
			return;
		}
		if (!form.relation_with_child) {
			setErrTxts({ ...errTxts, relation_with_child: 'This field is required' });
			return;
		}
		if (!form.email) {
			setErrTxts({ ...errTxts, email: 'This field is required' });
			return;
		}
		if (!/^\S+@\S+\.\S+$/.test(form.email)) {
			setErrTxts({ ...errTxts, email: 'Please enter valid email' });
			return;
		}
		if (!form.phone) {
			setErrTxts({ ...errTxts, phone: 'This field is required' });
			return;
		}
		if (form.phone) {
			if (isPossiblePhoneNumber(form.phone, 'US') === false) {
				setErrTxts({ ...errTxts, phone: 'Please enter valid phone number' });
				return;
			}
		}
		if (form.emergency_contact === -1) {
			setErrTxts({ ...errTxts, emergency_contact: 'This field is required' });
			return;
		}
		if (!(form.can_pickup === 1 || form.can_pickup === 0)) {
			setErrTxts({ ...errTxts, can_pickup: 'This field is required' });
			return;
		}
		if (isEdit && contact.role === 'parent') {
			form.is_parent = 1;
		} else {
			form.is_parent = 0;
		}
		if (selectedFile) {
			const filename = getImageUrl(selectedFile);
			setIsAdding(true);
			uploadFile(selectedFile, filename)
				.then((response) => {
					form.photo = `${import.meta.env.VITE_S3_BASE_URL}${response}`;
					form.thumb = `${import.meta.env.VITE_S3_BASE_URL}${response}`;
					if (isEdit) {
						updateContact(contact?.id, history.location.state?.row.id, {
							...form,
							relation_id: form.relation_id === 3 ? 1 : form.relation_id,
						})
							.then((r) => {
								dispatch(
									Actions.showMessage({
										message: r.data.message,
										autoHideDuration: 3500,
										variant: 'success',
									})
								);
								if (token) {
									history.push(`studentform?token=${token}`, { tab: 'contacts' });
								} else {
									history.push(`students-student/${row.id}`, { tab: 'contacts' });
								}
							})
							.catch((err) => {
								if (err.response?.data?.errors) {
									setErrTxts(err.response.data.errors);
								} else {
									dispatch(
										Actions.showMessage({
											message: 'Failed to update contact.',
											autoHideDuration: 3500,
											variant: 'error',
										})
									);
								}
								('here');
							})
							.finally(() => setIsAdding(false));
					} else {
						setIsAdding(true);
						createContact(form)
							.then((resp) => {
								dispatch(
									Actions.showMessage({
										message: resp.data.message,
										autoHideDuration: 3500,
										variant: 'success',
									})
								);
								if (token) {
									history.push(`studentform?token=${token}`, { tab: 'contacts' });
								} else {
									history.push(`students-student/${row.id}`, { tab: 'contacts' });
								}
							})
							.catch((err) => {
								if (err.response?.data?.errors) {
									setErrTxts(err.response.data.errors);
								} else if (err.response?.data?.message) {
									dispatch(
										Actions.showMessage({
											message: err.response.data.message,
											autoHideDuration: 3500,
											variant: 'error',
										})
									);
								} else {
									dispatch(
										Actions.showMessage({
											message: 'Failed to add contact.',
											autoHideDuration: 3500,
											variant: 'error',
										})
									);
								}
							})
							.finally(() => setIsAdding(false));
					}
				})
				.catch((err) => {
					dispatch(
						Actions.showMessage({
							message: 'Failed to upload image.',
							autoHideDuration: 3500,
							variant: 'error',
						})
					);
					setIsAdding(false);
				});
		} else {
			if (isEdit) {
				setIsAdding(true);
				updateContact(contact?.id, history.location.state?.row?.id, {
					...form,
					relation_id: form.relation_id === 3 ? 1 : form.relation_id,
				})
					.then((r) => {
						dispatch(
							Actions.showMessage({
								message: r.data.message,
								autoHideDuration: 3500,
								variant: 'success',
							})
						);
						if (token) {
							history.push(`studentform?token=${token}`, { tab: 'contacts' });
						} else {
							history.push(`students-student/${row.id}`, { tab: 'contacts' });
						}
					})
					.catch((err) => {
						if (err.response?.data?.errors) {
							setErrTxts(err.response.data.errors);
						} else {
							dispatch(
								Actions.showMessage({
									message: 'Failed to update contact.',
									autoHideDuration: 3500,
									variant: 'error',
								})
							);
						}
					})
					.finally(() => setIsAdding(false));
			} else {
				setIsAdding(true);
				createContact(form)
					.then((resp) => {
						dispatch(
							Actions.showMessage({
								message: resp.data.message,
								autoHideDuration: 3500,
								variant: 'success',
							})
						);
						if (token) {
							history.push(`studentform?token=${token}`, { tab: 'contacts' });
						} else {
							history.push(`students-student/${row.id}`, { tab: 'contacts' });
						}
					})
					.catch((err) => {
						if (err.response?.data?.errors) {
							setErrTxts(err.response.data.errors);
						} else if (err.response?.data?.message) {
							dispatch(
								Actions.showMessage({
									message: err.response.data.message,
									autoHideDuration: 3500,
									variant: 'error',
								})
							);
						} else {
							dispatch(
								Actions.showMessage({
									message: 'Failed to add contact.',
									autoHideDuration: 3500,
									variant: 'error',
								})
							);
						}
					})
					.finally(() => setIsAdding(false));
			}
		}
	};

	const goBack = () => {
		if (token) {
			history.push(`studentform?token=${token}`, { tab: 'contacts' });
		} else {
			history.goBack();
		}
	};

	const BlueRadio = withStyles({
		root: {
			color: 'white',
			'&$checked': {
				color: 'white',
			},
			'&:hover': {
				background: 'white',
			},
		},
		icon: {
			width: 16,
			height: 16,
			borderRadius: '50%',
			backgroundColor: 'green',
			border: 'solid 1px #4DA0EE',
		},
		checked: {},
	})(({ icon, checkedIcon, classes, ...props }) => {
		return (
			<Radio
				checkedIcon={
					<i
						className={' fas fa-check rounded-full text-white'}
						style={{
							background: props.disabled ? 'grey' : '#4DA0EE',
							border: `solid 1px ${props.disabled ? '#b0b0b0' : '#4DA0EE'}`,
							fontSize: '1.4rem',
							padding: '.7rem',
						}}
					/>
				}
				icon={
					<i
						className="fas fa-check rounded-full fa-sm text-white"
						style={{
							background: 'white',
							border: `solid 1px ${props.disabled ? '#b0b0b0' : '#4DA0EE'}`,
							fontSize: '1.4rem',
							padding: '.7rem',
							color: 'white',
						}}
					/>
				}
				{...props}
			/>
		);
	});

	const appAccessFieldDisabled = isEdit && !!form.is_primary_account;
	const primaryAccountFieldDisabled = isEdit && contact.is_primary_account;

	return (
		<>
			<div className="w-9/12 mx-auto my-64 pb-80">
				<div className="flex items-center flex-nowrap justify-between mx-auto mt-[11px] mb-16">
					<PageHeader title={isEdit ? 'Edit Contact' : 'Add Contact'} onBack={goBack} />
				</div>

				<div className="bg-white rounded p-32">
					<div className="flex justify-center flex-wrap md:flex-nowrap gap-12 md:gap-64">
						<div className="mx-40 image-top">
							<div
								className="relative pic-upload-overlay cursor-pointer"
								onClick={() => inputRef.current.click()}
							>
								<Avatar style={{ height: '140px', width: '140px' }} className="mx-auto" src={preview} />
								<div className="pp-input-overlay contactupload">
									<i className="fa fa-2x fa-camera" />
								</div>
								<input
									onChange={onSelectFile}
									type="file"
									name="image"
									id="image"
									className="hidden"
									accept="image/*"
									ref={inputRef}
								/>
							</div>
						</div>

						<div
							className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-32 flex-grow"
							style={{ rowGap: '1.2rem', columnGap: '3.2rem' }}
						>
							<TextField
								name="first_name"
								onChange={handleChange}
								value={form.first_name}
								label="First Name"
								className="w-2/3 my-12 width-first-name"
								error={!!errTxts.first_name}
								helperText={errTxts.first_name}
								required
							/>
							<TextField
								error={!!errTxts.last_name}
								helperText={errTxts.last_name}
								name="last_name"
								onChange={handleChange}
								value={form.last_name}
								label="Last Name"
								required
								className="w-2/3 my-12 width-first-name"
							/>
							<FormControl
								className="w-2/3 my-12 width-first-name"
								required
								error={!!errTxts.relation_id}
							>
								<InputLabel id="state-label">Select Contact Type</InputLabel>
								<Select
									onChange={handleContactTypeChange}
									value={form.relation_id}
									name="relation_id"
									labelId="state-label"
									id="status"
									placeholder=""
								>
									{/* {isCurrentPrimaryAccount
										? relations?.map((relation) => {
												// if the current user is the primary account holder, they must be a parent or legal guardian
												if (relation.id === 1 || relation.id === 3) {
													return (
														<MenuItem key={relation.id} value={relation.id}>
															{relation.name}
														</MenuItem>
													);
												}
										  })
										: */}
									{relations ? (
										relations?.map((relation) => {
											return (
												<MenuItem key={relation.id} value={relation.id}>
													{relation.name}
												</MenuItem>
											);
										})
									) : (
										<></>
									)}
								</Select>
								{errTxts.relation_id && <FormHelperText>{errTxts.relation_id}</FormHelperText>}
							</FormControl>
							{form.relation_id !== 1 && form.relation_id !== 3 ? (
								<TextField
									name="relation_with_child"
									onChange={handleChange}
									value={form.relation_with_child}
									label="Relation With Child"
									className="w-2/3 my-12 width-first-name"
									error={!!errTxts.relation_with_child}
									required
									helperText={errTxts.relation_with_child}
								/>
							) : (
								<FormControl
									className="my-12 width-relation-with-child"
									error={!!errTxts.relation_with_child}
									required
								>
									<InputLabel id="state-label">Relation With Child</InputLabel>
									<Select
										onChange={handleChange}
										value={
											form.relation_with_child === 'Legal Guardian'
												? form.relation_with_child
												: form.relation_with_child?.toLowerCase()
										}
										name="relation_with_child"
										labelId="state-label"
										id="status"
										placeholder=""
										className="width-relation-with-child"
										// Legal Guardian is only available for 3, so diasble the select menu
										disabled={form.relation_id === 3}
									>
										{form.relation_with_child === 'Legal Guardian' || form.relation_id === 3 ? (
											<MenuItem value="Legal Guardian">Legal Guardian</MenuItem>
										) : (
											[
												<MenuItem value="father" key="father">
													Father
												</MenuItem>,
												<MenuItem value="mother" key="mother">
													Mother
												</MenuItem>,
											]
										)}
									</Select>
									{errTxts.relation_with_child && (
										<FormHelperText>{errTxts.relation_with_child}</FormHelperText>
									)}
								</FormControl>
							)}
							<TextField
								// disabled={isEdit}
								error={!!errTxts.email}
								helperText={errTxts.email}
								name="email"
								onChange={handleChange}
								value={form.email}
								label="Email Address"
								required
								className="w-2/3 my-12 width-first-name"
							/>
							<CustomPhoneInput
								required
								value={form.phone}
								onChange={handleChange}
								label={'Contact Number'}
								helperText={errTxts.phone}
								error={!!errTxts.phone}
								className="w-full"
								name="phone"
							/>

							<FormControl error={!!errTxts.can_pickup} variant="standard" className="w-2/3 my-12">
								<div className="flex flex-col gap-6">
									<FormLabel component="legend">
										<span className={`text-pd-xs hd-pickup `}>Can Pickup *</span>
									</FormLabel>
									<RadioGroup
										onChange={({ target }) => {
											setForm({
												...form,
												can_pickup: +target.value,
											});
										}}
										value={form.can_pickup}
									>
										<div className="flex gap-20 items-center">
											<FormControlLabel
												value={1}
												control={<BlueRadio />}
												label={<span className="option-pickup mt-4 block">Yes</span>}
											/>
											<FormControlLabel
												value={0}
												control={<BlueRadio />}
												label={<span className="option-pickup mt-4 block">No</span>}
											/>
										</div>
									</RadioGroup>
								</div>

								{errTxts.can_pickup && <FormHelperText>{errTxts.can_pickup}</FormHelperText>}
							</FormControl>
							<FormControl error={!!errTxts.emergency_contact} variant="standard" className="w-2/3 my-12">
								<div className="flex flex-col gap-6">
									<FormLabel component="legend">
										<span className={`text-pd-xs hd-pickup `}>Emergency Contact *</span>
									</FormLabel>
									<RadioGroup
										onChange={({ target }) => {
											setForm({
												...form,
												emergency_contact: target.value === 'true' ? true : false,
											});
										}}
										value={form.emergency_contact}
									>
										<div className="flex gap-20 items-center">
											<FormControlLabel
												value={true}
												control={<BlueRadio />}
												label={<span className="option-pickup mt-4 block">Yes</span>}
											/>
											<FormControlLabel
												value={false}
												control={<BlueRadio />}
												label={<span className="option-pickup mt-4 block">No</span>}
											/>
										</div>
									</RadioGroup>
								</div>
								{errTxts.emergency_contact && (
									<FormHelperText>{errTxts.emergency_contact}</FormHelperText>
								)}
							</FormControl>
							{/* 1 = parent, 3 = legal guardian */}
							{[1, 3].includes(form.relation_id) && !token && (
								<>
									<FormControl
										error={!!errTxts.is_primary_account}
										variant="standard"
										className="w-2/3 my-12"
									>
										<div className="flex flex-col gap-6">
											<FormLabel component="legend">
												<span className={`text-pd-xs hd-pickup `}>
													Primary Account Holder *
												</span>
											</FormLabel>
											<RadioGroup
												onChange={({ target }) => {
													if (+target.value === 1) {
														setGrantedAppAccessChanged(true);
														setForm({
															...form,
															is_primary_account: +target.value,
															accees_given_by: +form.id,
														});
													} else {
														setGrantedAppAccessChanged(false);
														setForm({
															...form,
															is_primary_account: +target.value,

															accees_given_by: contact?.accees_given_by || 0,
														});
													}
												}}
												value={form.is_primary_account}
											>
												<div className="flex gap-20 items-center">
													<FormControlLabel
														value={1}
														control={<BlueRadio />}
														label={<span className="option-pickup mt-4 block">Yes</span>}
													/>
													<FormControlLabel
														value={0}
														control={<BlueRadio />}
														label={<span className="option-pickup mt-4 block">No</span>}
													/>
												</div>
											</RadioGroup>
										</div>

										{errTxts.is_primary_account && (
											<FormHelperText>{errTxts.is_primary_account}</FormHelperText>
										)}
									</FormControl>
								</>
							)}
							<FormControl
								error={!!errTxts.is_app_access}
								variant="standard"
								className="w-2/3 my-12"
								disabled={!!appAccessFieldDisabled}
							>
								<div className="flex flex-col gap-6">
									<FormLabel component="legend">
										<span
											className="text-pd-xs "
											style={{
												color: appAccessFieldDisabled ? '#b0b0b0' : 'black',
											}}
										>
											Granted App Access *
										</span>
									</FormLabel>
									<RadioGroup
										onChange={({ target }) => {
											setForm({ ...form, accees_given_by: +target.value });
											setGrantedAppAccessChanged(true);
										}}
										value={
											isEdit && !grantedAppAccessChanged
												? form.is_app_access_label === 'Primary Account Holder' ||
												  form.is_app_access_label === 'Granted App Access'
													? form.id
													: 0
												: form.accees_given_by
										}
									>
										<div className="flex gap-20 items-center">
											<FormControlLabel
												value={form.id || 1}
												control={<BlueRadio />}
												label={
													<span
														className="option-pickup mt-4 block"
														style={{
															color: appAccessFieldDisabled ? '#b0b0b0' : 'black',
														}}
													>
														Yes
													</span>
												}
											/>
											<FormControlLabel
												value={0}
												control={<BlueRadio />}
												label={
													<span
														className="option-pickup mt-4 block"
														style={{
															color:
																isEdit && appAccessFieldDisabled ? '#b0b0b0' : 'black',
														}}
													>
														No
													</span>
												}
											/>
										</div>
									</RadioGroup>
								</div>

								{errTxts.is_app_access && <FormHelperText>{errTxts.is_app_access}</FormHelperText>}
							</FormControl>
						</div>
					</div>
					{isAdding ? (
						<div className="flex justify-center mt-36">
							<CircularProgress className="mx-auto" />
						</div>
					) : (
						<div className="flex justify-center w-max mt-36 mx-auto" style={{ gap: '20px' }}>
							<CustomButton variant="secondary" onClick={goBack}>
								Cancel
							</CustomButton>
							<CustomButton
								variant="primary"
								onClick={() => {
									handleSubmit();
								}}
							>
								{isEdit ? 'Update' : 'Add'}
							</CustomButton>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default AddEditContact;
