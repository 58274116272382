import * as authActions from '@/app/auth/store/actions';
import CalendarIconSmall from '@/icons/TopNav/CalendarIconSmall';
import CheckinsIconSmall from '@/icons/TopNav/CheckinsIconSmall';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import {
	AccountCircleOutlined,
	AddCircleOutlineRounded,
	ExitToAppOutlined,
	KeyboardArrowDownOutlined,
} from '@material-ui/icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function UserMenu(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const [userMenu, setUserMenu] = useState(null);

	const userMenuClick = (event) => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	const viewProfileLink = {
		route: user.role[0] === 'super_admin' ? '/superadminprofile' : '/profile-view',
	};
	return (
		<>
			<Button className="h-64 p-0 mb-4 mr-8" onClick={userMenuClick} id="user-menu-btn">
				{user.data.photoURL ? (
					<Avatar
						src={user.data.photoURL}
						imgProps={{
							style: {
								objectFit: 'contain',
							},
						}}
					/>
				) : (
					<Avatar className=" w-40 h-40 text-pd-sm md:text-pd-lg">{user.data.displayName[0]}</Avatar>
				)}

				<div className="hidden md:flex flex-col mx-8 items-start">
					<Typography component="span" className="normal-case font-600 flex">
						{user.data.displayName}
					</Typography>
				</div>
				<KeyboardArrowDownOutlined className="text-16" />
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				classes={{
					paper: 'py-8',
				}}
			>
				{!user.role || user.role.length === 0 ? (
					<>
						<MenuItem component={Link} to="/login" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>lock</Icon>
							</ListItemIcon>
							<ListItemText primary="Login" />
						</MenuItem>
						<MenuItem component={Link} to="/register" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>person_add</Icon>
							</ListItemIcon>
							<ListItemText primary="Register" />
						</MenuItem>
					</>
				) : (
					<>
						<MenuItem
							component={Link}
							to={viewProfileLink.route}
							onClick={userMenuClose}
							role="button"
							id="my-profile-btn"
						>
							<ListItemIcon className="min-w-40">
								<AccountCircleOutlined />
							</ListItemIcon>
							<ListItemText primary="My Profile" />
						</MenuItem>
						<MenuItem
							component={Link}
							to="/calendar"
							onClick={userMenuClose}
							role="button"
							id="my-profile-btn"
							className="md:hidden"
						>
							<ListItemIcon className="min-w-40">
								<CalendarIconSmall />
							</ListItemIcon>
							<ListItemText primary="Calendar" />
						</MenuItem>
						<MenuItem
							component={Link}
							to="/checkinout"
							onClick={userMenuClose}
							role="button"
							id="my-profile-btn"
							className="md:hidden flex"
						>
							<ListItemIcon className="min-w-40">
								<CheckinsIconSmall />
							</ListItemIcon>
							<ListItemText primary="Check-ins" />
						</MenuItem>
						<MenuItem
							component={Link}
							to="/calendar-addevent"
							onClick={userMenuClose}
							role="button"
							id="my-profile-btn"
							className="md:hidden flex"
						>
							<ListItemIcon className="min-w-40">
								<AddCircleOutlineRounded />
							</ListItemIcon>
							<ListItemText primary="Events" />
						</MenuItem>
						<MenuItem
							id="logout-btn"
							onClick={() => {
								const initialState = {
									role: [], // guest
									data: {
										displayName: 'John Doe',
										photoURL: '',
										email: 'johndoe@withinpixels.com',
										shortcuts: ['calendar', 'mail', 'contacts', 'todo'],
									},
								};
								dispatch(authActions.logoutUser());
								dispatch(authActions.setUserData(initialState));
								userMenuClose();
							}}
						>
							<ListItemIcon className="min-w-40">
								<ExitToAppOutlined />
							</ListItemIcon>
							<ListItemText primary="Logout" />
						</MenuItem>
					</>
				)}
			</Popover>
		</>
	);
}

export default UserMenu;
