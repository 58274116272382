import React, { useState, useEffect } from 'react';
import history from '@/@history';
import { getAgeDetails, calculateDueOverDue } from '@/utils/utils';
import { getStudent } from '@/app/services/students/students';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import StudentView from '@/app/fuse-layouts/shared-components/student-form/StudentView';
import MedicalView from '@/app/fuse-layouts/shared-components/student-form/MedicalView';
import ImmunizationView from '@/app/fuse-layouts/shared-components/student-form/ImmunizationView';
import RoomAttachmentsView from '@/app/fuse-layouts/shared-components/student-form/RoomAttachmentsView';
import ContactsView from '@/app/fuse-layouts/shared-components/student-form/ContactView';
import TopProgress from '@/app/fuse-layouts/shared-components/student-form/TopProgress';

const useStyles = makeStyles((theme) => ({
	content: {
		position: 'relative',
		display: 'flex',
		overflow: 'auto',
		flex: '1 1 auto',
		flexDirection: 'column',
		width: '100%',
		'-webkit-overflow-scrolling': 'touch',
		zIndex: 2,
	},
}));

function NewLayout() {
	const classes = useStyles();
	const [currentState, setCurrentState] = useState(
		history.location?.state?.tab || localStorage.getItem('studentTab') || 'personal'
	);
	const params = useParams();
	const [student, setStudent] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [refresh, setRefresh] = useState(0);
	const [age, setAge] = useState('');

	useEffect(() => {
		if (currentState) {
			localStorage.setItem('studentTab', currentState);
		}
	}, [currentState]);

	useEffect(() => {
		let isMounted = true;
		setIsLoading(true);
		getStudent(params?.id)
			.then((res) => {
				if (!isMounted) return;
				setStudent(res?.data);
				setIsLoading(false);
			})
			.catch((err, ...rest) => {
				if (!isMounted) return;
				if (err.response?.status === 404) {
					history.push('/students');
				}
				setIsLoading(false);
			});
		return () => {
			isMounted = false;
		};
	}, [refresh, params?.id]);

	useEffect(() => {
		if (student) {
			setAge(getAgeDetails(dayjs(student?.date_of_birth), dayjs()));
		}
	}, [student]);

	return (
		<>
			<TopProgress currentState={currentState} setCurrentState={setCurrentState} />
			<div className="mt-0 md:mt-64 mx-auto mb-80 pb-40" style={{ width: '75%' }}>
				{currentState === 'personal' && (
					<StudentView
						student={student}
						isLoading={isLoading}
						setCurrentState={setCurrentState}
						isAdmin={true}
					/>
				)}
				{currentState === 'medication' && (
					<MedicalView student={student} isLoading={isLoading} setCurrentState={setCurrentState} />
				)}
				{currentState === 'room-attachments' && (
					<RoomAttachmentsView
						isAdmin={true}
						student={student}
						isLoading={isLoading}
						setCurrentState={setCurrentState}
						setStudent={setStudent}
						refresh={refresh}
						setRefresh={setRefresh}
					/>
				)}
				{currentState === 'contacts' && (
					<ContactsView
						isAdmin={true}
						student={student}
						isLoading={isLoading}
						setStudent={setStudent}
						setCurrentState={setCurrentState}
						refresh={refresh}
						setRefresh={setRefresh}
					/>
				)}
				{currentState === 'immunizations' && (
					<ImmunizationView
						student={student}
						age={age}
						isLoading={isLoading}
						refresh={refresh}
						setRefresh={setRefresh}
						setCurrentState={setCurrentState}
						isAdmin={true}
					/>
				)}
			</div>
		</>
	);
}

export default NewLayout;
