import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import { uploadRosterJSON } from '@/app/services/students/students';
import * as Actions from '@/app/store/actions';
import { isInValidName } from '@/utils/utils';
import {
	CircularProgress,
	Dialog,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import XLSX from 'xlsx';
import SuccessDialog from './SuccessDialog';

dayjs.extend(customParseFormat);

function FileValidator({ file, type, rooms }) {
	const dispatch = useDispatch();
	const [rows, setRows] = useState([]);
	const [errList, setErrList] = useState([]);
	const [isValidating, setIsValidating] = useState(true);
	const [errs, setErrs] = useState(false);
	const [open, setOpen] = useState(false);
	const [isAdding, setIsAdding] = useState(false);

	const handleProceedAnyway = () => {
		setOpen(true);
	};

	useEffect(() => {
		if (type === 'local') {
			const reader = new FileReader();
			reader.onload = (e) => {
				const data = e.target.result;
				const readedData = XLSX.read(data, { type: 'binary' });
				const wsname = readedData.SheetNames[0];
				const ws = readedData.Sheets[wsname];
				const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false });
				const arrays = dataParse.filter((array) => array.length !== 0);
				console.log(arrays);
				const validFormat = [
					'Student First Name',
					'Student Last Name',
					'Gender',
					'Date of Birth',
					'Homeroom',
					'Parent/Legal Guardian First Name',
					'Parent/Legal Guardian Last Name',
					'Relation to Student',
					'Parent/Legal Phone',
					'Parent/Legal Email',
					'Address line 1',
					'Address line 2',
					'City',
					'State',
					'Zip',
					"Doctor's Name",
					"Doctor's Phone",
					'Allergies',
					'Medications',
					'Additional Notes',
				];

				arrays[0].shift();
				// Doesn't have enough table headers
				if (arrays[0].length < validFormat.length) {
					dispatch(
						Actions.showMessage({
							message: 'Roster format is invalid. Please use the format provided.',
							variant: 'error',
							autoHideDuration: 3000,
						})
					);
					setOpen(false);
					dispatch(Actions.closeDialog());
					return;
				}
				// Doesn't have required table headers
				for (let i = 0; i < validFormat.length; i++) {
					if (arrays[0][i] !== validFormat[i]) {
						dispatch(
							Actions.showMessage({
								message: 'Roster format is invalid. Please use the format provided.',
								variant: 'error',
								autoHideDuration: 3000,
							})
						);
						setOpen(false);
						dispatch(Actions.closeDialog());
						return;
					}
				}
				arrays.shift();
				arrays.forEach((arr) => arr.shift());
				setRows(arrays);
				const temp = [];
				for (let i = 0; i < arrays.length; i += 1) {
					for (let j = 0; j < arrays[i].length; j += 1) {
						arrays[i][j] = arrays[i][j]?.trim();
					}
				}

				// Check for not matching room names
				for (let i = 0; i < arrays.length; i += 1) {
					temp[i] = [];
					if (!rooms.map((room) => room.toLowerCase()).includes(arrays[i][4]?.toLowerCase())) {
						temp[i][4] = 'Room not available';
					}
				}

				// Check for first name

				for (let i = 0; i < arrays.length; i += 1) {
					if (isInValidName(arrays[i][0])) {
						temp[i][0] = 'Invalid first name';
					}
				}

				// Check for last name

				for (let i = 0; i < arrays.length; i += 1) {
					if (isInValidName(arrays[i][1])) {
						temp[i][1] = 'Invalid last name';
					}
				}

				// Check for gender value
				for (let i = 0; i < arrays.length; i += 1) {
					if (!['male', 'female', 'm', 'f'].includes(arrays[i][2]?.toLowerCase())) {
						temp[i][2] = 'Incorrect gender';
					}
				}

				// Check for valid dob

				for (let i = 0; i < arrays.length; i += 1) {
					if (!dayjs(arrays[i][3]).isValid()) {
						temp[i][3] = 'Invalid date';
					}
				}
				// Check for parent first name

				for (let i = 0; i < arrays.length; i += 1) {
					if (isInValidName(arrays[i][5])) {
						temp[i][5] = 'Invalid parent first name';
					}
				}

				// Check for parent last name

				for (let i = 0; i < arrays.length; i += 1) {
					if (isInValidName(arrays[i][6])) {
						temp[i][6] = 'Invalid parent second name';
					}
				}

				// Check for relation value
				for (let i = 0; i < arrays.length; i += 1) {
					if (!['father', 'mother', 'legal guardian'].includes(arrays[i][7]?.toLowerCase())) {
						temp[i][7] = 'Incorrect Relation. Please use "Mother", "Father", or "Legal Guardian"';
					}
				}

				// Check for valid phone number
				for (let i = 0; i < arrays.length; i += 1) {
					const phoneRegex = /^(\(\d{3}\)\s?\d{3}-\d{4}|\d{3}-\d{3}-\d{4}|\d{10})$/;

					// parent/guardian phone
					if (arrays[i][8] && !phoneRegex.test(arrays[i][8])) {
						console.log(arrays[i][8]);
						temp[i][8] = 'Invalid phone number';
					}

					// doctor phone
					if (arrays[i][16] && !phoneRegex.test(arrays[i][16])) {
						console.log(arrays[i][16]);
						temp[i][16] = 'Invalid phone number';
					}
				}

				// Check for valid parent email
				for (let i = 0; i < arrays.length; i += 1) {
					if (temp[i][9] && !/^\S+@\S+\.\S+$/.test(arrays[i][9])) {
						temp[i][9] = 'Invalid email';
					}
				}

				// Check for empty table cells
				for (let i = 0; i < arrays.length; i += 1) {
					for (let j = 0; j < 12; j += 1) {
						// if (!arrays[i][j]) {
						// 	temp[i][j] = 'Missing Value';
						// }
						if (!arrays[i][j]) {
							switch (j) {
								case 0:
									temp[i][j] = 'Missing First Name';
									break;
								case 1:
									temp[i][j] = 'Missing Last Name';
									break;
								case 2:
									temp[i][j] = 'Missing Gender';
									break;
								case 3:
									temp[i][j] = 'Missing Date of Birth';
									break;
								case 4:
									temp[i][j] = 'Missing Home Room';
									break;
								case 5:
									temp[i][j] = 'Missing Parent/Guardian First Name';
									break;
								case 6:
									temp[i][j] = 'Missing Parent/Guardian Last Name';
									break;
								case 7:
									temp[i][j] = 'Missing Relation to Student';
									break;
								case 8:
									temp[i][j] = 'Missing Parent/Guardian Phone';
									break;
								case 9:
									temp[i][j] = 'Missing Parent/Guardian Email';
									break;
								default:
									break;
							}
						}
					}
				}
				setIsValidating(false);
				setErrList(temp);
			};
			reader.readAsBinaryString(file);
		}
	}, [rooms]);

	// Check if errors exist or not
	useEffect(() => {
		if (isValidating) {
			return;
		}
		if (errList.flat().length) {
			setErrs(true);
		} else {
			setErrs(false);
			uploadData();
		}
	}, [errList]);

	const toTitleCase = (str) => {
		return str.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	};

	const uploadData = () => {
		const allrows = rows.map((array) => ({
			first_name: array[0],
			last_name: array[1],
			gender:
				array[2]?.toLowerCase() === 'm'
					? 'male'
					: array[2]?.toLowerCase() === 'f'
					? 'female'
					: array[2].toLowerCase(),
			dob: dayjs(array[3]).format('YYYY-MM-DD'),
			home_room: array[4],
			parent_first_name: array[5],
			parent_last_name: array[6],
			emergency_contact: true,
			relation_with_child:
				array[7].toLowerCase() === 'legal guardian' ? toTitleCase(array[7]) : array[7].toLowerCase(),
			select: array[7].toLowerCase() === 'legal guardian' ? toTitleCase(array[7]) : 'parent',
			parent_phone: array[8],
			parent_email: array[9],
			address_line_1: array[10],
			address_line_2: array[11],
			city: array[12],
			state: array[13],
			zip_code: array[14],
			doctor_name: array[15],
			doctor_phone: array[16],
			allergies: array[17],
			medications: array[18],
			additional_notes: array[19],
			is_primary_account: 1,
		}));
		if (!allrows.length) {
			setOpen(false);
			dispatch(Actions.closeDialog());
			dispatch(
				Actions.showMessage({
					message: 'You uploaded an empty file',
					autoHideDuration: 1500,
					variant: 'error',
				})
			);
		}
		const data = allrows.filter((_, i) => !errList[i]?.length);

		if (data.length === 0) {
			setOpen(false);
			dispatch(Actions.closeDialog());
			dispatch(
				Actions.showMessage({
					message: 'There is no valid data available to upload',
					autoHideDuration: 1500,
					variant: 'error',
				})
			);
			return;
		}

		uploadRosterJSON({ data, roster_type: 'perfect_day' })
			.then((res) => {
				setOpen(false);
				dispatch(Actions.closeDialog());
				dispatch(
					Actions.openDialog({
						children: <SuccessDialog />,
					})
				);
			})
			.catch((err) => {
				console.log({ ...err });
			});
	};

	return (
		<div className="p-32">
			{!isValidating && errs ? (
				<>
					<div className="flex justify-end">
						<IconButton onClick={() => dispatch(Actions.closeDialog())}>
							<CloseIcon />
						</IconButton>
					</div>
					<TableContainer id="Scrollable-table" component={Paper} style={{ height: '60vh' }}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell
										component="th"
										scope="row"
										className="bg-white studentTableHeader whitespace-nowrap"
										align="left"
										size="small"
									>
										Sr. No
									</TableCell>
									<TableCell
										className="bg-white studentTableHeader whitespace-nowrap"
										align="left"
										size="small"
									>
										First Name
									</TableCell>
									<TableCell
										className="bg-white studentTableHeader whitespace-nowrap"
										align="left"
										size="small"
									>
										Last Name
									</TableCell>
									<TableCell className="bg-white studentTableHeader" align="left" size="small">
										Gender
									</TableCell>
									<TableCell
										className="bg-white studentTableHeader whitespace-nowrap"
										align="left"
										size="small"
									>
										Date of Birth
									</TableCell>
									<TableCell
										className="bg-white studentTableHeader whitespace-nowrap"
										align="left"
										size="small"
									>
										Home Room
									</TableCell>
									<TableCell
										className="bg-white studentTableHeader whitespace-nowrap"
										align="left"
										size="small"
									>
										Parent/Legal Guardian First Name
									</TableCell>
									<TableCell
										className="bg-white studentTableHeader whitespace-nowrap"
										align="left"
										size="small"
									>
										Parent/Legal Guardian Last Name
									</TableCell>
									<TableCell
										className="bg-white studentTableHeader whitespace-nowrap"
										align="left"
										size="small"
									>
										Relation to Student
									</TableCell>
									<TableCell
										className="bg-white studentTableHeader whitespace-nowrap"
										align="left"
										size="small"
									>
										Parent/Legal Guardian Phone
									</TableCell>
									<TableCell
										className="bg-white studentTableHeader whitespace-nowrap"
										align="left"
										size="small"
									>
										Parent/Legal Guardian Email
									</TableCell>
									<TableCell
										className="bg-white studentTableHeader whitespace-nowrap"
										align="left"
										size="small"
									>
										Address line 1
									</TableCell>
									<TableCell
										className="bg-white studentTableHeader whitespace-nowrap"
										align="left"
										size="small"
									>
										Address line 2
									</TableCell>
									<TableCell className="bg-white studentTableHeader" align="left" size="small">
										City
									</TableCell>
									<TableCell className="bg-white studentTableHeader" align="left" size="small">
										State
									</TableCell>
									<TableCell className="bg-white studentTableHeader" align="left" size="small">
										Zip
									</TableCell>
									<TableCell
										className="bg-white studentTableHeader whitespace-nowrap"
										align="left"
										size="small"
									>
										Doctor's Name
									</TableCell>
									<TableCell
										className="bg-white studentTableHeader whitespace-nowrap"
										align="left"
										size="small"
									>
										Doctor's Phone
									</TableCell>
									<TableCell className="bg-white studentTableHeader" align="left" size="small">
										Allergies
									</TableCell>
									<TableCell className="bg-white studentTableHeader" align="left" size="small">
										Medications
									</TableCell>
									<TableCell
										className="bg-white studentTableHeader whitespace-nowrap"
										align="left"
										size="small"
									>
										Additional Notes
									</TableCell>
									<TableCell className="bg-white studentTableHeader" align="left" size="small">
										Errors
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row, i) => {
									return (
										<TableRow key={i} className={`${errList[i]?.length ? 'bg-red-100' : ''}`}>
											{/* Sr. No */}
											<TableCell
												className="content-start"
												component="th"
												scope="row"
												align="left"
												size="small"
											>
												{i + 1}
											</TableCell>
											{/* First Name */}
											<TableCell
												className={`content-start ${
													errList[i]?.length
														? errList[i][0]?.length
															? 'bg-red-200'
															: ''
														: ''
												}`}
												align="left"
												size="small"
											>
												{row[0]}
											</TableCell>
											{/* Last Name */}
											<TableCell
												className={`content-start ${
													errList[i]?.length
														? errList[i][1]?.length
															? 'bg-red-200'
															: ''
														: ''
												}`}
												align="left"
												size="small"
											>
												{row[1]}
											</TableCell>
											{/* Gender */}
											<TableCell
												className={`content-start ${
													errList[i]?.length
														? errList[i][2]?.length
															? 'bg-red-200'
															: ''
														: ''
												}`}
												align="left"
												size="small"
											>
												{row[2]}
											</TableCell>
											{/* Date of Birth */}
											<TableCell
												className={`content-start ${
													errList[i]?.length
														? errList[i][3]?.length
															? 'bg-red-200'
															: ''
														: ''
												}`}
												align="left"
												size="small"
											>
												{row[3]}
											</TableCell>
											{/* Home Room */}
											<TableCell
												className={`content-start ${
													errList[i]?.length
														? errList[i][4]?.length
															? 'bg-red-200'
															: ''
														: ''
												}`}
												align="left"
												size="small"
											>
												{row[4]}
											</TableCell>
											{/* Parent/Legal Guardian First Name */}
											<TableCell
												className={`content-start ${
													errList[i]?.length
														? errList[i][5]?.length
															? 'bg-red-200'
															: ''
														: ''
												}`}
												align="left"
												size="small"
											>
												{row[5]}
											</TableCell>
											{/* Parent/Legal Guardian Last Name */}
											<TableCell
												className={`content-start ${
													errList[i]?.length
														? errList[i][6]?.length
															? 'bg-red-200'
															: ''
														: ''
												}`}
												align="left"
												size="small"
											>
												{row[6]}
											</TableCell>
											{/* Relation to Student */}
											<TableCell
												className={`content-start ${
													errList[i]?.length
														? errList[i][7]?.length
															? 'bg-red-200'
															: ''
														: ''
												}`}
												align="left"
												size="small"
											>
												{row[7]}
											</TableCell>
											{/* Parent/Legal Guardian Phone */}
											<TableCell
												className={`content-start ${
													errList[i]?.length
														? errList[i][8]?.length
															? 'bg-red-200'
															: ''
														: ''
												}`}
												align="left"
												size="small"
											>
												{row[8]}
											</TableCell>

											{/* Parent/Legal Guardian Email */}
											<TableCell
												className={`content-start ${
													errList[i]?.length
														? errList[i][9]?.length
															? 'bg-red-200'
															: ''
														: ''
												}`}
												align="left"
												size="small"
											>
												{row[9]}
											</TableCell>
											{/* Address line 1 */}
											<TableCell
												className={`content-start ${
													errList[i]?.length
														? errList[i][10]?.length
															? 'bg-red-200'
															: ''
														: ''
												}`}
												align="left"
												size="small"
											>
												{row[10]}
											</TableCell>

											{/* Address line 2 */}
											<TableCell
												className={`content-start ${
													errList[i]?.length
														? errList[i][11]?.length
															? 'bg-red-200'
															: ''
														: ''
												}`}
												align="left"
												size="small"
											>
												{row[11]}
											</TableCell>
											{/* City */}
											<TableCell
												className={`content-start ${
													errList[i]?.length
														? errList[i][12]?.length
															? 'bg-red-200'
															: ''
														: ''
												}`}
												align="left"
												size="small"
											>
												{row[12]}
											</TableCell>
											{/* State */}
											<TableCell
												className={`content-start ${
													errList[i]?.length
														? errList[i][13]?.length
															? 'bg-red-200'
															: ''
														: ''
												}`}
												align="left"
												size="small"
											>
												{row[13]}
											</TableCell>
											{/* Zip */}
											<TableCell className={`content-start`} align="left" size="small">
												{row[14]}
											</TableCell>
											{/* Doctor's Name */}
											<TableCell className="content-start" align="left" size="small">
												{row[15]}
											</TableCell>
											{/* Doctor's Phone */}
											<TableCell className="content-start" align="left" size="small">
												{row[16]}
											</TableCell>
											{/* Allergies */}
											<TableCell className="content-start" align="left" size="small">
												{row[17]}
											</TableCell>
											{/* Medications */}
											<TableCell className="content-start" align="left" size="small">
												{row[18]}
											</TableCell>
											{/* Additional Notes */}
											<TableCell className="content-start" align="left" size="small">
												{row[19]}
											</TableCell>
											{/* Errors */}
											<TableCell className="content-start" align="left" size="small">
												<ol>
													{errList[i]?.map((err, ind) => {
														return <li key={ind}>{err}</li>;
													})}
												</ol>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
					<div className="flex justify-end mx-auto mt-10">
						<CustomButton width={140} variant="primary" onClick={handleProceedAnyway}>
							Proceed Anyway
						</CustomButton>
					</div>
				</>
			) : !isValidating && !errs ? (
				<div className="flex justify-center items-center">
					<CircularProgress size={35} />
				</div>
			) : (
				<div className="flex justify-center items-center">
					<CircularProgress size={35} />
				</div>
			)}
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<div className="popup p-32">
					<div className="flex flex-col justify-around">
						<div className="icon-span">
							<h2>!</h2>
						</div>
						<h1 className="disable-text">Only valid data will be added to the system. Proceed?</h1>
						{isAdding ? (
							<CircularProgress size={35} />
						) : (
							<div className="flex gap-16 justify-center">
								<CustomButton onClick={() => setOpen(false)}>No</CustomButton>
								<CustomButton
									onClick={() => {
										setIsAdding(true);
										uploadData();
									}}
								>
									Yes
								</CustomButton>
							</div>
						)}
					</div>
				</div>
			</Dialog>
		</div>
	);
}

export default FileValidator;
