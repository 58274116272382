import DashboardCard from '@/app/fuse-layouts/layout1/components/Dashboard/DashboardCard';
import { getLoggedActivities } from '@/app/services/HomeService/homeService';
import { CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import RoomDetail from './RoomDetail';

function LoggedActivities() {
	const [loggedActivities, setLoggedActivities] = useState([]);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);
	const [refresh, setRefresh] = useState(false);
	const [viewAll, setViewAll] = useState(false); // Logged Activity View All State

	useEffect(() => {
		let mounted = true;
		getLoggedActivities(1)
			.then((res) => {
				if (mounted) {
					setLoggedActivities(res.data.data);
					setLoading(false);
					if (res.data.current_page < res.data.last_page) {
						setPage(res.data.current_page + 1);
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});

		navigator.serviceWorker.addEventListener('message', handleReceiveNotification);

		return () => {
			mounted = false;
			navigator.serviceWorker.removeEventListener('message', handleReceiveNotification);
		};
	}, [refresh]);

	const handleReceiveNotification = (e) => {
		if (
			e.data.data.click_action === 'room_wise_reminders' ||
			e.data.data.click_action === 'reminder_dashboard_room_activities' ||
			e.data.data.click_action === 'check_in_out_data_notification'
		) {
			setRefresh((prevState) => !prevState);
		}
	};

	useEffect(() => {
		let mounted = true;
		if (page !== 1) {
			getLoggedActivities(1)
				.then((res) => {
					if (mounted) {
						setLoggedActivities(res.data.data);
						setLoading(false);
						if (res.data.current_page < res.data.last_page) {
							setPage(res.data.current_page + 1);
						}
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
		return () => {
			mounted = false;
		};
	}, [page]);

	return (
		<DashboardCard
			title="Today's Logged Activities"
			showViewMore={!viewAll && loggedActivities.filter((room) => room.students_count).length > 5}
			onViewMore={() => setViewAll(true)}
		>
			<div className="activities-div  ">
				<div className={`flex flex-col gap-y-32 ${loading && 'items-center justify-center'}`}>
					{loading ? (
						<CircularProgress size={35} />
					) : loggedActivities.filter((room) => room.students_count).length ? (
						viewAll ? (
							loggedActivities
								.filter((room) => room.students_count)
								.map((loggedActivity) => (
									<RoomDetail
										roomDetail={loggedActivity}
										refresh={refresh}
										setRefresh={setRefresh}
										key={loggedActivity.id}
									/>
								))
						) : (
							loggedActivities
								.filter((room) => room.students_count)
								.slice(0, 5)
								.map((loggedActivity) => (
									<RoomDetail
										roomDetail={loggedActivity}
										refresh={refresh}
										setRefresh={setRefresh}
										key={loggedActivity.id}
									/>
								))
						)
					) : (
						<div className="flex justify-center font-semibold">No active rooms available</div>
					)}
				</div>
			</div>
		</DashboardCard>
	);
}

export default LoggedActivities;
