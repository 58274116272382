import * as Actions from '@/app/store/actions';
import { useDispatch } from 'react-redux';

function BlurInfoDialog({ fullScreen, setFalse }) {
	const dispatch = useDispatch();
	const handleClick = () => {
		if (fullScreen) {
			setFalse(false);
			console.log('False set');
		} else {
			dispatch(Actions.closeDialog());
			console.log('Dialog closed');
		}
	};
	return (
		<div className="bg-white px-32 min-h-[450px] max-h-[480px] pt-[10px] min-[400px]:min-w-[370px] min-[500px]:min-w-[440px]">
			<div className="flex justify-between py-[10px] px-0 content-center items-center mx-auto font-bold w-[90%]">
				<div>
					<h1 style={{ fontSize: '20px', fontWeight: '700' }}> Blur Information</h1>
				</div>
				<div>
					<i style={{ cursor: 'pointer' }} className="fas text-[20px]" onClick={handleClick} />
				</div>
			</div>
			<ul className="list-disc mx-32 gap-32">
				<li className="my-16">
					To blur any portion of the live stream, open the live stream in full screen mode and drag the
					rectangle over the area you intend to blur.
				</li>
				<li className="my-16"> Click 'Save' to save the blur effect on the live stream.</li>
				<li className="my-16"> To remove the blur effect, click the delete button. </li>
				<li className="my-16">
					To undo the action you just did, simply tap on the left pointing arrow. To redo an action, tap on
					the right pointing arrow.
				</li>
			</ul>
		</div>
	);
}

export default BlurInfoDialog;
