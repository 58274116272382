const InsetInputButton = ({ children, onClick, disabled = false }) => {
	const handleClick = () => {
		if (!disabled) {
			onClick();
		}
	};
	return (
		<button
			className={`px-4 min-h-full text-white text-pd-base  ${
				disabled ? 'bg-gray-300 rounded-r-8' : 'bg-gradient-blue rounded-r-4'
			}`}
			style={{
				width: '20%',
				maxWidth: '50px',
			}}
			type="button"
			onClick={handleClick}
		>
			{children}
		</button>
	);
};

export default InsetInputButton;
