import React from 'react';
import { useSpring, animated, easings } from '@react-spring/web';

const animations = {
	"transition.slideLeftIn": {
		from: { opacity: 0, x: -25 },
		to: { opacity: 1, x: 0 },
	},
	"transition.expandIn": {
		from: { opacity: 0, scale: 0.9 },
		to: { opacity: 1, scale: 1 },
	},
};
const FuseAnimate = (props) => {
	const { children, animation, duration = 300, delay = 50, ...rest } = props;

	const selectedAnimation = animations[animation] || animations['transition.slideLeftIn'];

	const springs = useSpring({
		from: selectedAnimation.from,
		to: selectedAnimation.to,
		config: { duration, easing: easings.easeInOutCubic },
		delay,
	});

	return (
		<animated.div style={springs} {...rest}>
			{children}
		</animated.div>
	);
};


export default React.memo(FuseAnimate);
